import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import PropTypes from 'prop-types';
import dedaBackground from "../../assets/img/deda-background.jpg";
import AuthNavbar from "components/Navbars/AuthNavbar.js";

import {
  Container,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

export default function Logout({ setToken }) {
  
  return(
    <div></div>
  )
}

Logout.propTypes = {
  setToken: PropTypes.func.isRequired
}
// import imgEdit from "../assets/img/edit.png"
// import imgDelete from "../assets/img/delete.png"
// import imgAdd from "../assets/img/add.png"
// import imgExpand from "../assets/img/expand.png"
// import imgInfo from "../assets/img/info.png"
// import imgSort from "../assets/img/sort.png"
// import imgDown from "../assets/img/down.png"
// import imgUp from "../assets/img/up.png"

// import imglogoDemo from "../assets/img/brand/iflow.png";
// import imglogo from "../assets/img/header-brand.png";

// import imgPrimaryBackground from "../assets/img/background.jpg";
// import GlobalParametersHelper from "./GlobalParameters-helper"

export const AssetHelper = {
    Icons: {
        edit: null,// imgEdit,
        // delete: imgDelete,
        // add: imgAdd,
        // expand: imgExpand,
        info: null //imgInfo,
        // sort: imgSort,
        // down: imgDown,
        // up: imgUp,
    },
    // Logo: {
    //     getMain: () => {
    //         // if (GlobalParametersHelper.getIsDemo()) {
    //         //     return imglogoDemo;
    //         // }

    //         return imglogo;
    //     }
    // },
    // Background: {
    //     Primary: imgPrimaryBackground
    // }

}

export default AssetHelper
import {
  get, getAsync,
  post, postAsync,
  put, putAsync,
  del, deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
  ContentTypeEnum
  } from "./apiCallerHelper.core";
  
export async function getUserCurrentAsync() {
    var route = "/api/auth/user/";
    return getAsync(route);
  }

import moment from "moment";
import Moment from "moment";
import 'moment/locale/el';

const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY';
const UI_INPUT_DATE_FORMAT = 'YYYY-MM-DD';

export const getDateOnly = (value) => {
    return getDateOnlyWithFormat(value, DEFAULT_DATE_FORMAT);
  }

export const getDateOnlyForUi = (value) => {
    return getDateOnlyWithFormat(value, UI_INPUT_DATE_FORMAT);
  }

  export const getDateOnlyWithFormat = (value, format) => {
    //console.log(value);

    if (value == null) {
        return null;
    }
    
    if (value.includes('T')){
        value  = value.split('T')[0];
    }

    let formats = [
        'YYYY-MM-DD',
        'YYYY/MM/DD',                   
        'DD/MM/YYYY',
        'DD-MM-YYYY',
    ]

   
    let result = null;
    
    formats.forEach(format => {            
        if (result == null || !result.isValid()) {
            result = Moment(value, format);
        }                
    });
    if (result.isValid() && result.year() > '2001') {
        result = result.format(format);
        return result;
    }

    return null;
  }
// export const getDateTypeOnlyMoment = (value) => {
//     let date = getDateOnly(value);    
//     const dateMomement = moment.parseZone(date, DEFAULT_DATE_FORMAT);
//     let result = dateMomement.valueOf();
//     return result;    
//   }


export function Clone(object) {
    return JSON.parse(JSON.stringify(object));
}

export function GetEnumsIntoKeyValue(constantEnum, descriptionFieldName) {
    var result = [];
    for (const key in constantEnum) {
        var item = { value: key };
        item[descriptionFieldName] = constantEnum[key];
        result.push(item);
    }
    return result;
}

export function Sort(array, fieldName, direction) {
    direction = direction || 1;
    return array.sort((a, b) => (a[fieldName] ?? '' > b[fieldName] ?? '') ? direction : direction * -1);
}

export function getBoolean(value) {
    if (!value) {
        return false;
    }
    if (typeof value === 'boolean') {
        return value;
    } 
    if (value == 1 ||
        value.toString().toLowerCase().trim() == '1' ||
        value.toString().toLowerCase().trim() == 'true' ||
        value.toString().toLowerCase().trim() == 'yes'
    ) {
        return true;
    }

    return false;
}

export function isNullOrEmptyString(value){
    return (!value || value.trim().length === 0)
}
import {
  get,
  getAsync,
  post,
  postAsync,
  put,
  putAsync,
  del,
  deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
} from "./apiCallerHelper.core";

import {
  getUserCurrentAsync
} from "./apiCallerHelper.auth"

import {
  getAllWorkflows
} from "./apiCallerHelper.workflows"

import {
  getWorkflowInstanceById,
  getWorkflowInstanceDetailsById,
  postUpdateWorkflowInstances,
  postApproveWorkflowInstances,
  postAssignWorkflowInstances,
  postDeclineWorkflowInstances,
  checkSerialAndUpdate,
  newInstanceSystemic,
  getFundingFlag,
  respondToFunding
} from "./apiCallerHelper.workflowinstances"

import {
  getAllConsumers,
  getConsumerDetails,
  getConsumerInstances,
  changeConsumer
} from "./apiCallerHelper.consumer"

import {
  getAllSuppliers,
  getSuppliersProfile,
  postSuppliersRequest,
  postSuppliersSavedata,
  postSuppliersSuccession,
  postSuppliersCrmSaveData,
  getSuppliersHistory,
} from "./apiCallerHelper.suppliers"

import {
  // getAllMeters,
  setMOPBilling,
  setMOPDeclaration
} from "./apiCallerHelper.meters"

import {
  getAllProperties,
  getPropertyDetails,
  postNewPropertyGIS,
  updatePropertyGIS,
  savePropertyChanges,
  changePropertyInability,
  transferInstanceProperty,
  deleteProperty,
} from "./apiCallerHelper.property"

import {
  getExportConnections,
  getExportTechnicalReview,
} from "./apiCallerHelper.export"

import {
  getGridViewDataById,
  getPageViewDataById,         
} from "./apiCallerHelper.views";

import {
  getFilesStatusByInstanceId,
  getFilesForFunding,
  getAllInstanceFilesByInstanceId,
  deleteFileByFileId
} from "./apiCallerHelper.files"

export const ApiCallerHelper = {
  hasInitialisedToken: () => {
    return hasInitialisedToken();
  },
  Common: {
    get,
    post,
    delete: del,
  },
  Auth: {
    getUserCurrentAsync: getUserCurrentAsync,  
  },
  Users: {
    getCurrentAsync: getUserCurrentAsync,
  }, 
  Consumer: {
    getAllConsumers: getAllConsumers,
    getConsumerDetails: getConsumerDetails,
    getConsumerInstances: getConsumerInstances,
    changeConsumer: changeConsumer,
  },
  Workflows: {
    getAllWorkflows: getAllWorkflows,
  },
  WorkflowInstances: {
    getWorkflowInstanceById : getWorkflowInstanceById,
    getWorkflowInstanceDetailsById : getWorkflowInstanceDetailsById,
    postUpdateWorkflowInstances : postUpdateWorkflowInstances,
    postApproveWorkflowInstances : postApproveWorkflowInstances,
    postAssignWorkflowInstances : postAssignWorkflowInstances,
    postDeclineWorkflowInstances : postDeclineWorkflowInstances,
    checkSerialAndUpdate: checkSerialAndUpdate,
    newInstanceSystemic: newInstanceSystemic,
    getFundingFlag : getFundingFlag,
    respondToFunding : respondToFunding
  },
  Suppliers: {
    getAllSuppliers: getAllSuppliers,
    getSuppliersProfile: getSuppliersProfile,
    postSuppliersRequest: postSuppliersRequest,
    postSuppliersSavedata : postSuppliersSavedata,
    postSuppliersSuccession: postSuppliersSuccession,
    postSuppliersCrmSaveData: postSuppliersCrmSaveData,
    getSuppliersHistory: getSuppliersHistory,
  },
  Meters: {
    setMOPBilling : setMOPBilling,
    setMOPDeclaration : setMOPDeclaration,
  },
  Property : {
    getAllProperties: getAllProperties,
    getPropertyDetails: getPropertyDetails,
    postNewPropertyGIS: postNewPropertyGIS,
    updatePropertyGIS: updatePropertyGIS,
    savePropertyChanges: savePropertyChanges,
    changePropertyInability: changePropertyInability,
    transferInstanceProperty: transferInstanceProperty,
    deleteProperty: deleteProperty,
  },
  Export: {
    getExportConnections: getExportConnections,
    getExportTechnicalReview: getExportTechnicalReview,
  }, 
  Views : {
    getGridViewDataById : getGridViewDataById,
    getPageViewDataById : getPageViewDataById,
  },
  Files: {
    getFilesStatusByInstanceId: getFilesStatusByInstanceId,
    getAllInstanceFilesByInstanceId : getAllInstanceFilesByInstanceId,
    getFilesForFunding : getFilesForFunding,
    deleteFileByFileId : deleteFileByFileId,
  },
};

export default ApiCallerHelper;


import React from 'react';

import {
    Button,
    Row,
    Col,
    Input,
    Table
  } from "reactstrap";

import axios from 'axios';

import Modal from 'react-modal';
import Moment from "moment";
import 'moment/locale/el';

class ReportIssue extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error: null,
            dynamicData : props.dynamicData,
            dynamicActions: props.dynamicActions,
            instance : props.instance,
            logged_user : props.logged_user,
            modalFieldName: '',
            modalFieldStepId: '',
            modalFieldActionId: '',
            comment: '',
        };

        this.handleCloseModalSubmit = this.handleCloseModalSubmit.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }

    handleOpenModal(evt, fieldName) {
        var res = evt.split("/");
        console.log(evt);

        this.setState({
            modalFieldName: fieldName,
            modalFieldStepId: res[0],
            modalFieldActionId: res[1],
            showModal: true,
        });
    }

    handleCloseModalSubmit() {
        console.log(this.state.comment);
        fetch(process.env.REACT_APP_API_LINK + '/api/instances/addcomment/', {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }),
                body: JSON.stringify({
                    'InstanceId': this.state.instance.Id,
                    'StepId': this.state.modalFieldStepId,
                    'ActionId': this.state.modalFieldActionId,
                    'Comment': this.state.comment
                })
            })
            .then(data => {
                console.log(data);
            })

        this.setState({ showModal: false });
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }

    updateComment(evt) {
        this.setState({
            comment: evt.target.value
        });
    }

    render() {
        return (
            <> 
                {this.state.dynamicData.step.EditPermission.includes(this.state.logged_user.PersonRole) && this.state.instance.IsAssigned && (this.state.instance.UserAssignedId == this.state.logged_user.Id) ?
                    <>
                    <Button
                        color="warning"
                        onClick={evt => this.handleOpenModal(this.state.dynamicData.step.Id + "/" + this.state.dynamicActions.action.Id, this.state.dynamicActions.action.Name)}
                        size="sm"
                    >
                        Αναφορά προβλήματος
                    </Button>
                    </> : <> </>
                }

                <Modal 
                    isOpen={this.state.showModal}
                    contentLabel="Προσθήκη Σχολίου"
                    ariaHideApp={false}
                    style={{
                        overlay: {
                            
                        },
                        content: {
                            position: 'absolute',
                            top: '40%',
                            left: '30%',
                            marginTop: '-50px',
                            marginLeft: '-30px',
                            width: '35%',
                            height: '30%'
                        }
                    }}
                >
                    <label
                        className="form-control-label"
                        htmlFor="input-username"
                    >
                        Σχολιο για {this.state.modalFieldName}
                    </label>
                    <Input
                        className="form-control-alternative"
                        defaultValue=''
                        id='comment'
                        placeholder='Προσθέστε το σχόλιό σας'
                        onChange={evt => this.updateComment(evt)}
                        // type={dynamicActions.action.Type}
                    />
                    <p></p>
                    <Button
                        color="primary"
                        onClick={this.handleCloseModalSubmit}
                        size="sm"
                    >
                        Προσθήκη σχολίου
                    </Button>
                    {/* <Button
                        color="primary"
                        onClick={this.handleCloseModal}
                        size="sm"
                    >
                        Απενεργοποίηση σχολίου
                    </Button> */}
                    <button onClick={this.handleCloseModal}>Κλείσιμο</button>
                </Modal>
            </>
        );
    }     
}

export default ReportIssue;
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';
import { MDBDataTable } from 'mdbreact';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Table,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import 'moment/locale/el';
import Modal from 'react-bootstrap/Modal';

import "../../../../assets/css/modals.css";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ES6


import "../../../../assets/css/datatables.css";

var modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, false] }],
        [{'script' : 'sub'}],
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image']
    ],
};
 
var formats = [
    'header',
    'script',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
];

class Templates extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      error: null,
      userDetails: [],
      templates: [],
      name: '',
      text: '',
      show: false
    };

    this.handleChange = this.handleChange.bind(this)
  };

  async componentDidMount() {
    if (localStorage.getItem('token')) {
        await fetch(process.env.REACT_APP_API_LINK + "/api/users/current/", {
            method: 'get',
            headers: new Headers({
                'Authorization' : 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        })
        .then(res => res.json())
        .then((result) => {
            console.log(result);
            this.setState({ 
            userDetails : result
            });
        })
    
        await axios.get(process.env.REACT_APP_API_LINK + "/api/documents/all/", {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        })
        .then((res) => {
            const templates2 = res.data;
            templates2.forEach(function (part, index) {
                this[index].Buttons =
                    <React.Fragment>
                        <Button
                        color="primary"
                        href={"/basicLayout/template/" + this[index].Id}
                        size="sm"
                        >
                        Προβολή/Επεξεργασία
                        </Button>
                        <Button
                            color="danger"
                            // onClick={}
                            size="sm"
                        >
                            Διαγραφή
                        </Button>
                    </React.Fragment>
                console.log(index)
            }, templates2);

            this.datas = {
                columns: [
                    {
                        label: 'Ονομα Προτυπου ▼',
                        field: 'Name',
                        sort: 'asc',
                        width: 100
                    },
                    {
                        label: 'Δημιουργηθηκε Απο ▼',
                        field: 'CreatedBy',
                        sort: 'asc',
                        width: 100
                    },
                    {
                        label: 'Τροποποιηθηκε Απο ▼',
                        field: 'EditedBy',
                        sort: 'asc',
                        width: 100
                    },
                    {
                        label: 'Ημ. Τελ. Τροποποιησης ▼',
                        field: 'LastEdit',
                        sort: 'asc',
                        width: 100
                    },
                    {
                        label: 'Μεταβαση ▼',
                        field: 'Buttons',
                        sort: 'asc',
                        width: 150
                    }
                ],
                rows: res.data
            };

            const templates = res.data;
            this.setState({ templates });
            console.log(templates);
        })
    };
  } 

    changeName(value) {
        this.state.name = value;
        this.setState({
          name: this.state.name
        });
    }

    handleChange(value) {
        this.setState({ text: value })
        console.log(value);
    }

    setShow(boolean) {
        this.setState({
            show: boolean
        });
    }

    saveTemplate() {
        fetch(process.env.REACT_APP_API_LINK + '/api/documents/new/', {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }),
            body: JSON.stringify({
                'Name' : this.state.name,
                'Template': this.state.text,
            })
        })
        .then(data => {
            this.setState({ showModal: false });
            window.location.reload(false);
        })
    }

  render() {
    return (
      <>
        <GenericHeader />
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                    <Row className="align-items-center" style={{marginBottom: '20px'}}>
                        <div className="col">
                            <h3 className="mb-0">Πρότυπα Εγγράφων</h3>
                        </div>
                        <div className="col text-right">
                            <Button
                                color="success"
                                onClick={() => this.setShow(true)}
                                size="sm"
                                type="button"
                            >
                                Νέο Πρότυπο
                            </Button>
                        </div>
                    </Row>
                    <MDBDataTable
                        bordered
                        exportToCSV
                        hover
                        strip="true"
                        responsive
                        data={this.datas}
                        id="mdbdttable"
                        entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                        nodatacomponent="Δεν υπάρχουν δεδομένα"
                        searchLabel="Αναζήτηση"
                        snext="Suivant"
                        paginationLabel={['Προηγούμενο', 'Επόμενο']}
                        infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                        entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                    />
                </CardHeader>
              </Card>
            </Col>
          </Row>
          
          <div className='push'></div>
            <Modal
                show={this.state.show}
                size="lg"
                onHide={() => this.setShow(false)}
                dialogClassName="modal-templates"
                aria-labelledby="example-custom-modal-styling-title-sm"
                style={{ opacity: 1 }}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title-sm">
                        Νέο Πρότυπο Εγγράφου
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <div>
                            <Row>                          
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-document-name"
                                        >
                                            Όνομα Προτύπου
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-document-name"
                                            defaultValue={this.state.name}
                                            placeholder={'Νέο Πρότυπο'}
                                            name="Description"
                                            type="text"
                                            onChange={evt => this.changeName(evt.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                    

                    <ReactQuill 
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        value={this.state.text}
                        onChange={this.handleChange} 
                        // style={{height: '300px'}}
                    />
                    <br/>
                    <br/>
                    <div className="text-center">
                        <Button
                            color="success"
                            onClick={() => this.saveTemplate()}
                            size="sm"
                        >
                            Αποθήκευση Προτύπου
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </Container>
      </>
    );
  }
}

export default Templates;

import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";

// import 'rsuite/dist/styles/rsuite-default.css';

class BasicNavbar extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      error: null,
      activeNav: 1,
      isLoaded: false,
      user: [],
      roles: []
    };
}
  
    componentDidMount() {
      if (localStorage.getItem('token')) {
          fetch(process.env.REACT_APP_API_LINK + "/api/users/current/", {
          method: 'get',
          headers: new Headers({
              'Authorization' : 'Bearer ' + localStorage.getItem('token'),
              'Content-Type': 'application/x-www-form-urlencoded'
          })
          })
          .then(res => res.json())
          .then(
          (result) => {
              this.setState({
              isLoaded: true,
              user: result,
              });
          },
          (error) => {
              this.setState({
              isLoaded: true,
              error
              });
          }
          )
      };
    }

  handleClick = instanceId => {
    localStorage.removeItem('token');
  }

  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Link
              className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
              to="/"
            >
              {/* {this.props.brandText} */}
            </Link>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {this.state.user.DisplayName }  <i className="fa fa-caret-down" />
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  {/* <DropdownItem to="/basicLayout/profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>Προφίλ</span>
                  </DropdownItem>
                  <DropdownItem to="/basicLayout/settings" tag={Link}>
                    <i className="ni ni-settings-gear-65" />
                    <span>Ρυθμίσεις</span>
                  </DropdownItem> */}
                  {/* <DropdownItem to="/basicLayout/support" tag={Link}>
                    <i className="ni ni-support-16" />
                    <span>Υποστήριξη</span>
                  </DropdownItem> */}
                  {/* <DropdownItem divider /> */}
                  <DropdownItem href="/auth/logout" onClick={() => { this.handleClick() }}>
                    <i className="ni ni-button-power" />
                    <span>Αποσύνδεση</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default BasicNavbar;
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';
import { MDBDataTable } from 'mdbreact';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col,
    Form,
    FormGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    InputGroup,
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import 'moment/locale/el';

import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';

import "../../../../assets/css/modals.css";

class UserRoles extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            userDetails: [],
            // employees: [],
            roles: [],
            show: false,
            roleType: '',
            roleName: '',
        };
    };

    async componentDidMount() {
        if (localStorage.getItem('token')) {
            await fetch(process.env.REACT_APP_API_LINK + "/api/users/current/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
            .then(res => res.json())
            .then((result) => {
                console.log(result);

                if (result.PersonRole != 'Master')
                    this.props.history.goBack();

                this.setState({
                    userDetails: result
                });
            })

            await fetch(process.env.REACT_APP_API_LINK + "/api/roles/all/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
            .then(res => res.json())
            .then((result) => {
                console.log(result);
                this.setState({
                    roles: result
                })
            })

            // await fetch(process.env.REACT_APP_API_LINK + "/api/users/employees/", {
            //     method: 'get',
            //     headers: new Headers({
            //         'Authorization': 'Bearer ' + localStorage.getItem('token'),
            //         'Content-Type': 'application/x-www-form-urlencoded'
            //     })
            // })
            // .then(res => res.json())
            // .then((result) => {
            //     console.log(result);
            //     this.setState({
            //         employees: result
            //     })
            // })
        };
    }

    setShow(boolean) {
        this.setState({
            show: boolean
        });
    }

    updateName(value) {
        this.state.roleName = value;
        this.setState({
            roleName: this.state.roleName
        });
    }

    updateType(value) {
        this.state.roleType = value;
        this.setState({
            roleType: this.state.roleType
        });
    }

    handleSubmit() {
        console.log(this.state.roleName, this.state.roleType);

        fetch(process.env.REACT_APP_API_LINK + "/api/roles/new/", {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                'Name': this.state.roleName,
                'Type': this.state.roleType,
            })
        })
        .then(res => res.json())
        .then((result) => {
            console.log(result);

            this.setShow(false);

            fetch(process.env.REACT_APP_API_LINK + "/api/roles/all/", {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    'Content-Type': 'application/x-www-form-urlencoded'
                })
            })
                .then(res => res.json())
                .then((result) => {
                    console.log(result);
                    this.setState({
                        roles: result
                    })
                })
        })
    }

    // deleteRole = (flowid) => {
    //     if (window.confirm('Είστε Σίγουρη/Σίγουρος?')) {
    //         fetch(process.env.REACT_APP_API_LINK + "/api/flows/delete/" + flowid + "/", {
    //             method: 'delete',
    //             headers: new Headers({
    //                 'Authorization': 'Bearer ' + localStorage.getItem('token'),
    //                 'Content-Type': 'application/x-www-form-urlencoded'
    //             })
    //         })
    //             .then(res => res.json())
    //             .then((result) => {
    //                 console.log(result);
    //                 fetch(process.env.REACT_APP_API_LINK + "/api/flows/all/", {
    //                     method: 'get',
    //                     headers: new Headers({
    //                         'Authorization': 'Bearer ' + localStorage.getItem('token'),
    //                         'Content-Type': 'application/x-www-form-urlencoded'
    //                     })
    //                 })
    //                     .then(res => res.json())
    //                     .then((result) => {
    //                         console.log(result);
    //                         this.setState({
    //                             flows: result
    //                         })
    //                     })
    //             })
    //     }
    // }

    isFormValid() {
        const { roleName, roleType } = this.state;
        return roleName && roleType;
    }

    render() {
        return (
            <>
                {this.state.userDetails.PersonRole == 'Master' ?
                    <>
                        <GenericHeader />

                        <Container className="mt--7" fluid>
                            <Row className="mt-5">
                                <Col className="mb-5 mb-xl-0" xl="12">
                                    <Card className="shadow">
                                        <CardHeader className="border-0">
                                            <Row className="align-items-center">
                                                <div className="col">
                                                    <h3 className="mb-0">Ρόλοι Χρηστών</h3>
                                                </div>
                                                <div className="col text-right">
                                                    <Button
                                                        color="success"
                                                        onClick={() => this.setShow(true)}
                                                        size="sm"
                                                    >
                                                        Προσθήκη Ρόλου

                                                    </Button>
                                                </div>

                                            </Row>
                                            <Table className="align-items-center table-flush" responsive style={{ marginTop: '20px' }}>
                                                <thead className="thead-light">
                                                    <tr>
                                                        <th scope="col">ΟΝΟΜΑ ΡΟΛΟΥ</th>
                                                        <th scope="col">ΕΝΕΡΓΕΙΕΣ</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.roles.map((role) =>
                                                        <tr key={role.Name}>
                                                            <td>{role.Name}</td>

                                                            <td>
                                                                <Button
                                                                    color="primary"
                                                                    href={"/basicLayout/role/" + role.Id}
                                                                    size="sm"
                                                                >
                                                                    Επεξεργασία
                                                                </Button>
                                                                <Button
                                                                    color="danger"
                                                                    // onClick={() => { this.deleteRole(role.Id) }}
                                                                    size="sm"
                                                                    title="Διαγραφή"
                                                                >
                                                                    <i className="fas fa-trash" />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>
                                        </CardHeader>
                                    </Card>
                                </Col>
                            </Row>

                            <div className='push'></div>
                            <Modal
                                show={this.state.show}
                                onHide={() => this.setShow(false)}
                                dialogClassName="modal-90w"
                                aria-labelledby="example-custom-modal-styling-title"
                                style={{ opacity: 1 }}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title id="example-custom-modal-styling-title">
                                        ΠΡΟΣΘΗΚΗ ΝΕΟΥ ΡΟΛΟΥ
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>

                                    <Form>
                                        <h6 className="heading-small text-muted mb-4">
                                            ΣΥΜΠΛΗΡΩΣΤΕ ΤΑ ΠΑΡΑΚΑΤΩ ΠΕΔΙΑ
                                        </h6>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-flow-name"
                                                        >
                                                            Όνομα Ρόλου
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="input-flow-name"
                                                            name="roleName"
                                                            placeholder="Όνομα Ρόλου"
                                                            type="text"
                                                            onChange={evt => this.updateName(evt)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="4">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-flow-name"
                                                        >
                                                            Τύπος Ρόλου
                                                        </label>
                                                        <Input
                                                            style={{ height: '40px', border: '1px solid rgb(118, 118, 118)' }}
                                                            className="form-control-alternative"
                                                            defaultValue={''}
                                                            id="input-first-name"
                                                            type="select"
                                                            onChange={evt => this.updateType(evt)}
                                                        >
                                                            <option value={''}>{'Τύπος Ρόλου'}</option>
                                                            <option value={'User'}>{'Απλός Χρήστης'}</option>
                                                            <option value={'Admin'}>{'Προϊστάμενος'}</option>
                                                            <option value={'Master'}>{'Διαχειριστής'}</option>
                                                        </Input>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>

                                    </Form>
                                    <div className="col text-right">
                                        <Button
                                            color="success"
                                            onClick={() => this.handleSubmit()}
                                            size="sm"
                                            disabled={!this.isFormValid()}
                                        >
                                            Προσθήκη
                                        </Button>

                                    </div>
                                </Modal.Body>
                            </Modal>

                        </Container>

                    </>
                    :
                    <> </>
                }
            </>
        );
    }

}

export default UserRoles;
import {
    get, getAsync,
    post, postAsync,
    put, putAsync,
    del, deleteAsync,
    getAuthorizationHeader,
    hasInitialisedToken,
    ContentTypeEnum
} from "./apiCallerHelper.core";

export async function getAllProperties() {
    var route = "/api/property/all/";
    return getAsync(route);
}

export async function getPropertyDetails(propertyId) {
    var route = "/api/property/property/" + propertyId + "/";
    return getAsync(route);
}

export async function postNewPropertyGIS(GISData) {
  var route = "/api/property/newgis/";
  let data = {
    Address : GISData.mainAddress,
    Num: GISData.addressNum,
    Postal: GISData.zipCode,
    City: GISData.city,
    Lat: GISData.lat,
    Lon: GISData.lon,
  }
  return postAsync(route, data);
}

export async function updatePropertyGIS(propertyId, GISData) {
  var route = "/api/property/editgis/";
  let data = {
    PropertyId: propertyId,
    Address : GISData.mainAddress,
    Num: GISData.addressNum,
    Postal: GISData.zipCode,
    City: GISData.city,
    Lat: GISData.lat,
    Lon: GISData.lon,
  }
  return putAsync(route, data);
}

export async function savePropertyChanges(propertyId, option, value) {
    var route = "/api/property/savechanges/";
    let data = {
        PropertyId: propertyId,
        Option: option,
        Value: value
    }
    return postAsync(route, data);
}

export async function changePropertyInability(propertyId, option, value) {
    var route = "/api/property/inability/";
    let data = {
        PropertyId: propertyId,  
        Option: option
    }
    return postAsync(route, data);
}

export async function transferInstanceProperty(propertyId, instanceId) {
    var route = "/api/property/transfer/";
    let data = {
        PropertyId: propertyId,
        InstanceId: instanceId,
    }
    console.log(data);
    return postAsync(route, data);
}

export async function deleteProperty(propertyId) {
    var route = "/api/property/" + propertyId + "/";
    
    return deleteAsync(route);
}
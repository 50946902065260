export const RedirectHelper = {
    RedirectTo: {
        reloadPage : () => { window.location.reload();},
        reloadPageFromCache : () => { window.location.reload(false);},
        // home: () => _location("/basicLayout/index/"),
        // login: () => _location("/auth/login"),
        // dynamicRedirect: (url) => _location(url),   
        // passwordChange: () => _location("/passwordchange/"),     
      },
    };
    
    function _locationRedirectReact(self, route) {
      self.props.history.push(route)
    }
    
    function _location(route) {
      window.location.replace(route);
    }

export default RedirectHelper;
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';
import { MDBDataTable } from 'mdbreact';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Form,
  FormGroup,
  Table,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import 'moment/locale/el';
import Modal from 'react-bootstrap/Modal';

import "../../../../assets/css/modals.css";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // ES6


import "../../../../assets/css/datatables.css";

var modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, false] }],
        [{'script' : 'sub'}],
        ['bold', 'italic', 'underline'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image']
    ],
};
 
var formats = [
    'header',
    'script',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
];

class Templates extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      error: null,
      userDetails: [],
      document: [],
      name: '',
      text: '',
      show: false
    };

    this.handleChange = this.handleChange.bind(this)
  };

  async componentDidMount() {
    if (localStorage.getItem('token')) {
        await fetch(process.env.REACT_APP_API_LINK + "/api/users/current/", {
            method: 'get',
            headers: new Headers({
                'Authorization' : 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        })
        .then(res => res.json())
        .then((result) => {
            console.log(result);
            this.setState({ 
                userDetails : result
            });
        })

        const handle = this.props.match.params.id;
        console.log(handle);
    
        await fetch(process.env.REACT_APP_API_LINK + "/api/documents/document/" + handle + "/", {
            method: 'get',
            headers: new Headers({
                'Authorization' : 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
        })
        .then(res => res.json())
        .then((result) => {
            console.log(result);
            this.setState({
                document: result,
                name : result.Name,
                text : result.Template
            });
        })
    };
  } 

    changeName(value) {
        this.state.name = value;
        this.setState({
          name: this.state.name
        });
    }

    handleChange(value) {
        this.setState({ text: value })
        console.log(value);
    }

    saveChanges() {
        fetch(process.env.REACT_APP_API_LINK + '/api/documents/update/', {
            method: 'PUT',
            headers: new Headers({
                'Authorization' : 'Bearer ' + localStorage.getItem('token'),
            }),
            body: JSON.stringify({
                'DocumentId' : this.state.document.Id,
                'Name' : this.state.name,
                'Template' : this.state.text
            })
        })
        .then(data => {
            window.location.reload(false);
        })
    }

  render() {
    return (
      <>
        <GenericHeader />
        <Container className="mt--9" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                    <Row className="align-items-center" style={{marginBottom: '10px'}}>
                        <div className="col">
                            <h3 className="mb-0">Πρότυπο Εγγράφου</h3>
                        </div>
                        <div className="col text-right">
                            <Button
                                color="success"
                                onClick={() => this.saveChanges()}
                                size="sm"
                                type="button"
                            >
                                Αποθήκευση Αλλαγών
                            </Button>
                        </div>
                    </Row>
                </CardHeader>
                <CardBody>
                    <Form>
                        <div>
                            <Row>                          
                                <Col lg="6">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-document-name"
                                        >
                                            Όνομα Προτύπου
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-document-name"
                                            defaultValue={this.state.name}
                                            placeholder={'Νέο Πρότυπο'}
                                            name="Description"
                                            type="text"
                                            onChange={evt => this.changeName(evt.target.value)}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>                          
                                <Col lg="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-document-name"
                                        >
                                            Δημιουργήθηκε Από
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-document-name"
                                            defaultValue={this.state.document.CreatedBy}
                                            placeholder={'Νέο Πρότυπο'}
                                            name="Description"
                                            type="text"
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-document-name"
                                        >
                                            Ημ. Τελευταίας Τροποποίησης
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-document-name"
                                            defaultValue={this.state.document.LastEdit}
                                            placeholder={'Νέο Πρότυπο'}
                                            name="Description"
                                            type="text"
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>
                                <Col lg="4">
                                    <FormGroup>
                                        <label
                                            className="form-control-label"
                                            htmlFor="input-document-name"
                                        >
                                            Τροποποιήθηκε Από
                                        </label>
                                        <Input
                                            className="form-control-alternative"
                                            id="input-document-name"
                                            defaultValue={this.state.document.EditedBy}
                                            placeholder={''}
                                            name="Description"
                                            type="text"
                                            disabled={true}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </CardBody>

                <CardBody>
                    <ReactQuill 
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        value={this.state.text}
                        onChange={this.handleChange} 
                        // style={{height: '300px'}}
                    />
                    <br/>
                    <br/>
                    <div className="text-center">
                        <Button
                            color="success"
                            onClick={() => this.saveChanges()}
                            size="sm"
                        >
                            Αποθήκευση Αλλαγών
                        </Button>
                    </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          
          <div className='push'></div>
        </Container>
      </>
    );
  }
}

export default Templates;

import {
    get, getAsync,
    post, postAsync,
    put, putAsync,
    del, deleteAsync,
    getAuthorizationHeader,
    hasInitialisedToken,
    ContentTypeEnum
} from "./apiCallerHelper.core";
    
export async function getAllConsumers() {
    var route = "/api/consumer/all/";
    return getAsync(route);
}

export async function getConsumerDetails(consumerId) {
    var route = "/api/consumer/details/" + consumerId + "/";
    return getAsync(route);
}

export async function getConsumerInstances(consumerId) {
    var route = "/api/consumer/instances/" + consumerId + "/";
    return getAsync(route);
}

    
export async function changeConsumer(consumerId, instanceSerial) {
    var route = "/api/consumer/change/";
    let data = {
        NewConsumerId : consumerId,
        SerialNumber : instanceSerial,
    }
    return postAsync(route, data);
}
import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';
import { MDBDataTable } from 'mdbreact';


// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  Container,
  Row,
  Col
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import Moment from "moment";
import 'moment/locale/el';

import "../../../../assets/css/datatables.css";

class CompletedInstances extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      error: null,
      userDetails: [],
      instances: [],
      dict: {},
    };
  };

  async componentDidMount() {
    if (localStorage.getItem('token')) {
      await fetch(process.env.REACT_APP_API_LINK + "/api/users/current/", {
        method: 'get',
        headers: new Headers({
          'Authorization' : 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      })
      .then(res => res.json())
      .then((result) => {
          console.log(result);
          this.setState({ 
            userDetails : result
          });
      })
    
      await axios.get(process.env.REACT_APP_API_LINK + "/api/instances/completed/", {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      })
      .then((res) => {  
        const statusLabels = [
          {wfName : "Αιτήματα Από Διοίκηση",  wfId: "06e5eef7-a8c0-4622-b8fe-db47c5727ec1", value : "1. Καταγραφή Αιτήματος",status: "1"},
          {wfName : "Αιτήματα Από Διοίκηση",  wfId: "06e5eef7-a8c0-4622-b8fe-db47c5727ec1", value : "2. Απάντηση Αιτήματος",status: "2"},
          {wfName : "Αιτήματα Από Διοίκηση",  wfId: "06e5eef7-a8c0-4622-b8fe-db47c5727ec1", value : "4. Ολοκλήρωση",status: "4"},
          {wfName : "Υποβολή Προσφοράς", wfId: "73fa8dd5-0d4f-4821-9e7e-7821ca2ca061", value : "1. Έλεγχος Τεχνικής και Επαγγελματικής Ικανότητας",status: "1"},
          {wfName : "Υποβολή Προσφοράς", wfId:  "73fa8dd5-0d4f-4821-9e7e-7821ca2ca061", value : "2. Έλεγχος Οικονομικής Επάρκειας",status: "2"},
          {wfName : "Υποβολή Προσφοράς", wfId:  "73fa8dd5-0d4f-4821-9e7e-7821ca2ca061", value : "3. Έλεγχος Δικαιολογητικών Συμμετοχής",status: "3"},
          {wfName : "Υποβολή Προσφοράς", wfId:  "73fa8dd5-0d4f-4821-9e7e-7821ca2ca061", value : "4. Έλεγχος Τρόπου Αξιολόγησης",status: "4"},
          {wfName : "Υποβολή Προσφοράς", wfId:  "73fa8dd5-0d4f-4821-9e7e-7821ca2ca061", value : "5. Πλήρωση Κριτηρίων και Απόφαση για Υποβολή",status: "5"},
          {wfName : "Υποβολή Προσφοράς", wfId:  "73fa8dd5-0d4f-4821-9e7e-7821ca2ca061", value : "6. Έκδοση Εγγυητικής Επιστολής Συμμετοχής",status: "6"},
          {wfName : "Υποβολή Προσφοράς", wfId:  "73fa8dd5-0d4f-4821-9e7e-7821ca2ca061", value : "7. Σύνταξη Τεχνικής Προσφοράς",status: "7"},
          {wfName : "Υποβολή Προσφοράς", wfId:  "73fa8dd5-0d4f-4821-9e7e-7821ca2ca061", value : "8. Σύνταξη Οικονομικής Προσφοράς",status: "8"},
          {wfName : "Υποβολή Προσφοράς", wfId:  "73fa8dd5-0d4f-4821-9e7e-7821ca2ca061", value : "9. Ανάρτηση Αρχείων στο ΕΣΗΔΗΣ",status: "9"},
          {wfName : "Υποβολή Προσφοράς", wfId:  "73fa8dd5-0d4f-4821-9e7e-7821ca2ca061", value : "10. Υποβολή Προσφοράς",status: "10"},
          {wfName : "Αίτημα Αδείας",   wfId: "b74bbed4-b78f-4b21-a7ee-ac5690ccad28", value : "1. Καταγραφή Αιτήματος",status: "1"},
          {wfName : "Αιτήματα Προς Διοίκηση", wfId: "c03732a8-5401-4fb6-abeb-208b627911e9", value : "1. Καταγραφή Αιτήματος",status: "1"},
          {wfName : "Αιτήματα Προς Διοίκηση", wfId: "c03732a8-5401-4fb6-abeb-208b627911e9", value : "2. Απάντηση Αιτήματος",status: "2"},
          {wfName : "Αιτήματα Προς Διοίκηση", wfId: "c03732a8-5401-4fb6-abeb-208b627911e9", value : "3. Ολοκλήρωση",status: "3"},
          {wfName : "Αιτήματα IT",	wfId:  "c4211394-acd2-48c4-b20a-efc4c6e7b763", value : "1. Καταγραφή Αιτήματος",status: "1"},
          {wfName : "Αιτήματα IT",	wfId:  "c4211394-acd2-48c4-b20a-efc4c6e7b763", value : "2. Απάντηση Αιτήματος",status: "2"},
          {wfName : "Αιτήματα IT",	wfId:  "c4211394-acd2-48c4-b20a-efc4c6e7b763", value : "3. Ολοκλήρωση",status: "3"},
          {wfName : "Αιτήματα Consulting", wfId: "efc9efd2-fb38-43b9-bfbc-4ca4e0c89246", value : "1. Καταγραφή Αιτήματος",status: "1"},
          {wfName : "Αιτήματα Consulting", wfId: "efc9efd2-fb38-43b9-bfbc-4ca4e0c89246", value : "2. Απάντηση Αιτήματος",status: "2"},
          {wfName : "Αιτήματα Consulting", wfId: "efc9efd2-fb38-43b9-bfbc-4ca4e0c89246", value : "3. Ολοκλήρωση",status: "3"}         
        ];

        function getNewStatus(instance) {                    
          let item = statusLabels.find(x=> x.wfId == instance.WorkflowId && x.status == instance.Status);
          return item?.value??'';                            
        }

        function getFlowDescription(instance) {
          let item = statusLabels.find(x=> x.wfId == instance.WorkflowId);

          return item?.wfName??'';                   
        }
        
        

        const instances2 = res.data;
        instances2.forEach(function (part, index) {
        this[index].NewStatus = getNewStatus(part)
        this[index].Flow = getFlowDescription(part)
        this[index].Buttons =
            <React.Fragment>
                {/* <Button
                  color="primary"
                  href={"/admin/details/" + this[index].Id}
                  size="sm"
                >
                  Στοιχεία Αίτησης
                </Button> */}
                <Button
                    color="primary"
                    href={"/basicLayout/instance/" + this[index].Id}
                    size="sm"
                >
                    Βήματα Αίτησης
                </Button>
            </React.Fragment>
        console.log(index)
        }, instances2);
        this.datas = {
          columns: [
              {
                label: 'Αριθμος Αιτησης ▼',
                field: 'SerialNumber',
                sort: 'asc',
                width: 100
              },
              {
                label: 'Κατασταση ▼',
                field: 'NewStatus',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Τελ. Ενημερωση ▼',
                field: 'Comments',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Ροη',
                field: 'Flow',
                sort: 'asc',
                width: 150
              },
              {
                label: 'Μεταβαση ▼',
                field: 'Buttons',
                sort: 'asc',
                width: 150
              }
            ],
            rows: res.data
        };
    
        const instances = res.data;
        this.setState({ instances });
        console.log(instances);
    })
    };
  }

  render() {
    return (
      <>
        <GenericHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="mb-5 mb-xl-0" xl="12">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <Row className="align-items-center" style={{marginBottom: '20px'}}>
                    <div className="col">
                      <h3 className="mb-0">Ολοκληρωμένες Εργασίες</h3>
                    </div>
                    <div className="col text-right">
                      {/* <Button
                        color="success"
                        href={"/basicLayout/newinstance"}
                        size="sm"
                      >
                        Καταχώρηση Νέας Εργασίας
                        
                      </Button> */}
                    </div>
                  </Row>
                  <MDBDataTable
                    bordered
                    exportToCSV
                    hover
                    strip="true"
                    responsive
                    data={this.datas}
                    id="mdbdttable"
                    entriesOptions={[10, 25, 50, 100, 500, 1000]} entries={10}
                    nodatacomponent="Δεν υπάρχουν δεδομένα"
                    searchLabel="Αναζήτηση"
                    snext="Suivant"
                    // nodatacomponent="Δεν υπάρχουν δεδομένα"
                    paginationLabel={['Προηγούμενο', 'Επόμενο']}
                    infoLabel={['Εμφανίζονται', 'έως', 'από', 'καταχωρήσεις']}
                    entriesLabel={'Εμφάνιση Αιτημάτων ανά: '}
                  /> 
                </CardHeader>
              </Card>
            </Col>
          </Row>

          {/* <Row>
            <Rating />
          </Row> */}
          
          
          <div className='push'></div>
        </Container>
      </>
    );
  }
}

export default CompletedInstances;

import React, { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import { CommonLoading } from 'react-loadingg';


import axios from 'axios';

import {
  Button,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Tooltip 
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import 'moment/locale/el';

class Maps extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error: null,
            user: [],
            startDate: new Date(),
            otherDay: new Date(),
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChange2 = this.handleChange2.bind(this);
        this.state.startDate.setMonth(this.state.otherDay.getMonth() - 2);
    }

    async componentDidMount() {
        const handle  = this.props.match.params.id;
        console.log(handle);

        await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
            method: 'get',
            headers: new Headers({
                'Authorization' : 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
          })
          .then(res => res.json())
          .then(
            (result) => {
              console.log(result);
              this.setState({
              user: result,
              });
            },
            (error) => {
              console.log(error);
                this.setState({
                error
                });
            }
          )
    };

    setShow(boolean) {
        this.setState({
            show: boolean
        });
    }

    getDates(startDate, endDate) {
        var dates = [],
            currentDate = startDate,
            addDays = function (days) {
                var date = new Date(this.valueOf());
                date.setDate(date.getDate() + days);
                return date;
            };
        while (currentDate <= endDate) {

            dates.push(currentDate.toLocaleDateString('en-GB'));
            currentDate = addDays.call(currentDate, 1);
        }
        this.state.array = dates;
        return dates;
    };

    getDates_call(date, otherDate) {
        var dates = this.getDates(date, otherDate);
    }

    handleChange(date) {
        this.setState({
            startDate: date
        });
    }

    handleChange2(date) {
        this.setState({
            otherDay: date
        })
    }

    render() {
        return (
            <>
                <InstanceHeader />
                <div className="header pb-4" style={{background: "linear-gradient(to right, #a8e6ff, #095d80)"}}>
                    <Container fluid>
                        <div className="header-body">
                            <Row>
                                <Col lg="8" xl="12">
                                    <Card className="card-stats mb-4 mb-xl-0">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <span className="h2 font-weight-bold mb-0">
                                                        Χάρτες
                                                    </span>
                                                </div>
                                            </Row>

                                            <Row>
                                                <p></p>
                                            </Row>

                                            <Row className="align-items-center">
                                                <div className="container-fluid">
                                                    <div className="row">
                                                        {/* <div className="col-sm">
                                                            Από
                                                            <DatePicker
                                                                selected={this.state.startDate}
                                                                onChange={this.handleChange}
                                                                name="startDate"
                                                                dateFormat="dd/MM/yyyy"
                                                            />
                                                        </div>
                                                        {this.getDates_call()}
                                                        <div className="col-sm">
                                                            Έως
                                                            <DatePicker
                                                                selected={this.state.otherDay}
                                                                onChange={this.handleChange2}
                                                                name="startDate"
                                                                dateFormat="dd/MM/yyyy"
                                                            />
                                                        </div> */}
                                                        <div className="col-sm" style={{ marginTop: '-10px' }}>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-first-name"
                                                            >
                                                                Κατηγορία Δεδομένων
                                                            </label>
                                                            <Input
                                                                style={{ height: '40px', border: '1px solid rgb(118, 118, 118)' }}
                                                                className="form-control-alternative"
                                                                defaultValue={''}
                                                                id="input-first-name"
                                                                type="select"
                                                                // onChange={evt => this.updateLocation(evt)}
                                                            >
                                                                <option value={'-'}>{'Όλες οι κατηγορίες'}</option>
                                                                {/* {this.state.locations.map((dynamicLocations) =>
                                                                    <option value={dynamicLocations.Id}>{dynamicLocations.City}</option>
                                                                )} */}
                                                            </Input>
                                                        </div>
                                                        {/* <div className="col-sm" style={{ marginTop: '-10px' }}>
                                                            <label
                                                                className="form-control-label"
                                                                htmlFor="input-first-name"
                                                            >
                                                                Είδος Χάρτη
                                                            </label>
                                                            <Input
                                                                style={{ height: '40px', border: '1px solid rgb(118, 118, 118)' }}
                                                                className="form-control-alternative"
                                                                defaultValue={''}
                                                                id="input-first-name"
                                                                type="select"
                                                                // onChange={evt => this.updateLocation(evt)}
                                                            >
                                                                <option value={'-'}>{'Όλες οι κατηγορίες'}</option>
                                                                <option value={'line'}>{'Γραμμή'}</option>
                                                                <option value={'pie'}>{'Πίτα'}</option>
                                                                <option value={'bar'}>{'Μπάρες'}</option>
                                                            </Input>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </Row>

                                            <Row>
                                                <p></p>
                                            </Row>

                                            <Row>
                                            <div className="col text-right">
                                                <Button
                                                    color="primary"
                                                    // onClick={}
                                                    size="sm"
                                                >
                                                    Προβολή
                                                </Button>
                                            </div>
                                                </Row>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>

                <Container className="mt-2" fluid>
                    <Row className="mt-4">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="border-0">
                                    <Row>
                                        <div className="col text-right">
                                            <Button
                                                color="warning"
                                                // onClick={}
                                                size="sm"
                                            >
                                                <i className="fas fa-pencil-alt" />
                                            </Button>
                                            <Button
                                                color="danger"
                                                // onClick={() => { this. }}
                                                size="sm"
                                                title="Διαγραφή"
                                            >
                                                <i className="fas fa-trash" />
                                            </Button>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
            </Container>
        </>
        );
    }
}

export default Maps;

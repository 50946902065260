
import React, { useState, useEffect, Fragment } from "react";
import AssetHelper from "../../../common/AssetHelper";
import RedirectHelper from "../../../common/redirectHelper";
import { Button, Card, CardHeader, Container, Row, Col, Util } from "reactstrap";
import {isNullOrEmptyString} from "../../../common/utils";
export const DynamicGridViewComponentCellExtentions = {
    CellStyle : {
        STYLE_BADGE : "STYLE_BADGE"
    },
    CellType : {
        CHECKBOX : "checkbox"
    },
    CellStyleRender : {  
        _getDefaultCellStyle(style){
          //let styleResult = {minWidth:"50px", maxWidth:"500px", overflowX:"hidden"}
          let styleResult = {};
          if (style) {
            styleResult = {...styleResult, ...(style??{})}
          }
          return styleResult                                      
        },
        _setStyleActions : (columnMetaDataDefinition, row, customActionsCallbackMethods) => {  
            if (columnMetaDataDefinition.id != "__action") {return;}
            const FlowInstance = ({_columnMetaDataDefinition, _row})=>{
              let flowInstance = (<React.Fragment></React.Fragment>);
              if (_columnMetaDataDefinition.flowInstance) {                
                  let bindField = _columnMetaDataDefinition.flowInstance.bindField;
                  flowInstance = (                    
                      <Button
                          color="link"
                          size="sm"
                          title="Βήματα"
                          onClick={() => {RedirectHelper.RedirectTo.flowInstance(_row[bindField]);}}
                      >                    
                          <img src={AssetHelper.Icons.edit}></img>
                    </Button>
                  )
              }
              return flowInstance;
            }          
           
            const InstanceDetail = ({ _columnMetaDataDefinition, _row }) => {
              let instanceDetail = <React.Fragment></React.Fragment>;
              if (_columnMetaDataDefinition.instanceDetail) {
                let bindField = _columnMetaDataDefinition.instanceDetail.bindField;
                let faClassName = "fa " + (_columnMetaDataDefinition.instanceDetail.faIcon??"");
                let hasFaIcon =  faClassName != "fa ";
                let hasIcon = AssetHelper.Icons.info != null;                
                let hasDefaultButton = (hasFaIcon == false) && (hasIcon == false);
                
                let RenderIcon = () => {
                    if (hasFaIcon == false) {return;}
                    return (<img src={AssetHelper.Icons.info}></img>)
                }
                let RenderFaIcon = () => {
                    if (hasFaIcon == false) {return;}
                    return (<i className={faClassName}></i>)
                }

                let RenderDefault = () => {
                    if (hasDefaultButton == false) {return;}
                    return (<>Στοιχεία Αίτησης</>)
                }
                
                let buttonClassName = "link";
                if (hasDefaultButton) {
                    buttonClassName = "primary";                    
                }
                instanceDetail = (
                  <Button
                    color={buttonClassName}
                    onClick={() => {
                      RedirectHelper.RedirectTo.details(_row[bindField]);
                    }}
                    size="sm"
                    title="Στοιχεία"
                  >
                       <RenderIcon/>
                       <RenderFaIcon/>
                       <RenderDefault/>       
                  </Button>
                );
              }
              return instanceDetail;
            };    
                      
            const CustomActions = ({_columnMetaDataDefinition, _row }) => {
              let customActions = <React.Fragment></React.Fragment>;
              if (_columnMetaDataDefinition.customActions) {
                _columnMetaDataDefinition.customActions.forEach(
                  (customAction) => {
                    let bindField = customAction.bindField;
                    let key = row[bindField];
                    let color = customAction.color || "link";
                    let title = customAction.title || "-";

                    let faClassName = "fa " + (_columnMetaDataDefinition.instanceDetail.faIcon??"");
                    let hasFaIcon =  faClassName != "fa ";
                    
                    let RenderIcon = () => {
                      let iconName = customAction.icon;
                      let icon = AssetHelper.Icons[iconName];
                      if (!icon) {
                        return;
                      }
                      return (
                        <img
                          style={{ width: 39, height: "auto" }}
                          src={icon}
                        ></img>
                      );                     
                    }    

                    let RenderFaIcon = () => {
                      if (hasFaIcon == false) {return ;}
                      return (<i className={faClassName}> </i>)
                    }

                    let RenderText = () => {                      
                      return (<>{title}</>)
                    }

                    function _getOnClickCallbackmethod() {
                      let callbackMethodName =
                        customAction.callbackMethodName || "";
                      let callbackMethod = (value) => {};
                      if (customActionsCallbackMethods) {
                        callbackMethod =
                          customActionsCallbackMethods[callbackMethodName];
                      }
                      return callbackMethod;
                    }

                    function _getIsVisible() {
                      //check is visible field
                      let isVisible = true;
                      if (customAction.isVisible == undefined) {
                        return isVisible;
                      }

                      if (
                        customAction.isVisible &&
                        typeof customAction.isVisible == "boolean"
                      ) {
                        isVisible = customAction.isVisible;
                        return isVisible;
                      }

                      isVisible =
                        isNullOrEmptyString(_row[customAction.isVisible]) ==
                        false;
                      return isVisible;
                    }
                    
                    /**
                     * Checks whether the specific action should be disabled based on given rules
                     * if the bind field matches the bind value                   
                     */
                    function _isDisabled() {
                      let disabled = customAction.disabled || null;
                      if (disabled == null) {return false;}
                      let disabledBindField = disabled.bindField;
                      let disabledBindValue = disabled.bindValue;

                      if (_row[disabledBindField] == disabledBindValue) {
                        return true;
                      }
                      return false;
                    }
                   

                    let isVisible = _getIsVisible();
                    if (isVisible == false) {
                      return;
                    }
                    let callbackMethod = _getOnClickCallbackmethod();
                    customActions = (
                      <>
                        {customActions}
                        <Button
                          color={color}
                          onClick={() => {
                            callbackMethod(row);
                          }}
                          size="sm"                          
                          title={title}
                          disabled={_isDisabled()}
                        >
                          <RenderIcon/>
                          <RenderFaIcon/>
                          <RenderText/>    
                        </Button>
                      </>
                    );
                  }
                );

                customActions = (<Fragment>{customActions}</Fragment>)
                return customActions;
              }
            }             
            let style= DynamicGridViewComponentCellExtentions.CellStyleRender._getDefaultCellStyle(columnMetaDataDefinition.style);                   
            row.__action = (
              <React.Fragment>
                <div style={style}>
                  <FlowInstance
                    _columnMetaDataDefinition={columnMetaDataDefinition}
                    _row={row}
                  />
                  <InstanceDetail
                    _columnMetaDataDefinition={columnMetaDataDefinition}
                    _row={row}
                  />  
                  <CustomActions
                    _columnMetaDataDefinition={columnMetaDataDefinition}
                    _row={row}
                  />                                  
                </div>
              </React.Fragment>
            );                                                                   
        },              
        _setStyleBadge : (columnMetaDataDefinition, value) => {     
            try {
              let className = "badge-primary";     
              let hasStyleBadge = columnMetaDataDefinition.hasStyleBadge;  
              let styleBadge = columnMetaDataDefinition.styleBadge 
              if (styleBadge) {
                // Create an array of objects that map a value to a class name
                let badgeMap = [
                    {value: styleBadge.secondary, className: "badge-secondary"},
                    {value: styleBadge.success, className: "badge-success"},
                    {value: styleBadge.info, className: "badge-info"},
                    {value: styleBadge.warning, className: "badge-warning"},
                    {value: styleBadge.danger, className: "badge-danger"},
                ];                
                // Iterate over the array and find the matching value                
                let match = badgeMap.find(item => 
                    item.value && 
                    (item.value??'')
                        .toLowerCase()
                        .split(",")
                        .includes((value??'').toLowerCase()));
                if (match) {
                  className = match.className;
                }                                                        
              } else if (!(hasStyleBadge ?? false)) {
                return ;
              }                        
              let style= DynamicGridViewComponentCellExtentions.CellStyleRender._getDefaultCellStyle(columnMetaDataDefinition.style);
              style.textAlign = "center";
              value = (
                <React.Fragment>
                <div style={style}>
                    <span className={"tool-theme-badge badge " + className }>
                    {value}
                    </span>
                </div>
                </React.Fragment>
             );                     
            } catch (error) {
                console.error(error);
            }  finally {
                return value;
            }    
        },     
        _setStyleText : (columnMetaDataDefinition, row) => {
            if (columnMetaDataDefinition.id === "__action") {return;}
            let id = columnMetaDataDefinition.id;            
            let key = "__column__" + id;            
            let value = row[id];

            const cellStyleRenders = [
                DynamicGridViewComponentCellExtentions.CellStyleRender._setMaxLength,
                DynamicGridViewComponentCellExtentions.CellStyleRender._setStyleBadge                          
              ];
           
            for (const cellStyleRender of cellStyleRenders) {
                value = cellStyleRender(columnMetaDataDefinition, value);
            }                         
            let style= DynamicGridViewComponentCellExtentions.CellStyleRender._getDefaultCellStyle(columnMetaDataDefinition.style);               
            row[key] = (
                <React.Fragment >
                    <div style={style}>{value}</div>                    
                </React.Fragment>
              );                  
        },
        _setCellType : (columnMetaDataDefinition, row) => {
            if (columnMetaDataDefinition.type !== DynamicGridViewComponentCellExtentions.CellType.CHECKBOX) {return;}
            let id = columnMetaDataDefinition.id;
            let key = "__column__" + id;
            let value = row[id];
            let style= DynamicGridViewComponentCellExtentions.CellStyleRender._getDefaultCellStyle(columnMetaDataDefinition.style);               
            style.textAlign = "center"            
            row[key] = (
                <React.Fragment ><div style={style}>{value}
                    {(value??false) === true ? <div><i style={{color: 'green'}} class="fa fa-check"></i></div> : <div><i style={{color: 'red'}} class="fa fa-times"></i></div> }                            </div>
                </React.Fragment>
              );
        },
        _setMaxLength : (columnMetaDataDefinition, value) => {
            if (!columnMetaDataDefinition.hasMaxLength) {return value;}
            let maxLength = columnMetaDataDefinition.hasMaxLength;
            if ((value??'').length > maxLength){
                value = value.substring(0, maxLength) + ". . .";               
            }     
            return value;                   
        }
    },
    SetCellStyles : (dataSource, columnMetadataDefinitions, customActionsCallbackMethods) => {         
        let hasColumnMetadataDefinitions = (columnMetadataDefinitions !== null && columnMetadataDefinitions.length>0);
        if (!hasColumnMetadataDefinitions) { return ;}
        columnMetadataDefinitions.forEach(columnMetaDataDefinition => {                                                   
            dataSource.forEach((row, index) => {                            
                DynamicGridViewComponentCellExtentions.CellStyleRender._setStyleActions(columnMetaDataDefinition, row, customActionsCallbackMethods);                
                DynamicGridViewComponentCellExtentions.CellStyleRender._setStyleText(columnMetaDataDefinition, row);
                DynamicGridViewComponentCellExtentions.CellStyleRender._setCellType(columnMetaDataDefinition, row);
            });
        });
    }
}
export default DynamicGridViewComponentCellExtentions
import React, { useState } from "react";

import { CommonLoading } from 'react-loadingg';

import axios from 'axios';

import {
  Button,
  Card,
  CardBody,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";

import "react-datepicker/dist/react-datepicker.css";
import Moment from "moment";
import 'moment/locale/el';

import StatsFilter from './StatsFilter';

class Statistics extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error: null,
            user: [],
        };
    }

    async componentDidMount() {
        await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
            method: 'get',
            headers: new Headers({
                'Authorization' : 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
          })
          .then(res => res.json())
          .then(
            (result) => {
              console.log(result);
              this.setState({
              user: result,
              });
            },
            (error) => {
              console.log(error);
                this.setState({
                error
                });
            }
          )
    };

    setShow(boolean) {
        this.setState({
            show: boolean
        });
    }

    render() {
        return (
            <>
                <InstanceHeader />
                <div className="header pb-4" style={{background: "linear-gradient(to right, #a8e6ff, #095d80)"}}>
                    <StatsFilter />
                </div>

                <Container className="mt-2" fluid>
                    <Row className="mt-4">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            {/* <Card className="bg-secondary shadow">
                                <CardHeader className="border-0">
                                    <Row>
                                        <div className="col text-right">
                                            <Button
                                                color="warning"
                                                // onClick={}
                                                size="sm"
                                            >
                                                <i className="fas fa-pencil-alt" />
                                            </Button>
                                            <Button
                                                color="danger"
                                                // onClick={() => { this. }}
                                                size="sm"
                                                title="Διαγραφή"
                                            >
                                                <i className="fas fa-trash" />
                                            </Button>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                </CardBody>
                            </Card> */}
                        </Col>
                    </Row>
            </Container>
        </>
        );
    }
}

export default Statistics;

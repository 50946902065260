import React from 'react';

import {
    Button,
    Row,
    Col,
    Input,
    Table
  } from "reactstrap";

import axios from 'axios';

import Moment from "moment";
import 'moment/locale/el';

class File extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error: null,
            dynamicData : props.dynamicData,
            dynamicActions: props.dynamicActions,
            instance : props.instance,
            logged_user : props.logged_user,
            enabled : props.enabled,
            files : props.files,
            // token : props.token
        };

        // this. = this..bind(this);
    }

    changeFilesStatus = (files) => { 
        this.props.filesCallback(files);
    }

    updateFile(evt, test) {
        console.log(test);
        this.setState({ fileUpload: evt.target.files[0] });
    }

    uploadFile(evt, fileid) {
        const formData = new FormData();
        var res = fileid.split("/");

        // Update the formData object 
        formData.append(
            "files",
            evt.target.files[0]
        );

        // Details of the uploaded file 
        console.log(evt.target.files[0]);
        console.log(formData);


        fetch(
            'https://rae-filemanager-staging.evolution-isa.gr/', {
                method: 'POST',
                body: formData,
            }
        )
        .then((response) => response.json())
        .then((result) => {
            console.log('Success:', result);

            fetch(process.env.REACT_APP_API_LINK + '/api/files/new/', {
                    method: 'POST',
                    headers: new Headers({
                        'Authorization': 'Bearer ' + localStorage.getItem('token'),
                    }),
                    body: JSON.stringify({
                        'Name': result[0].name,
                        'Path': result[0].path,
                        'Size': result[0].size,
                        'Type': result[0].type,
                        'InstanceId': this.state.instance.Id,
                        'StepId': res[0],
                        'ActionId': res[1]
                    })
                })
                .then(data => {
                    console.log(data);

                    axios.get(process.env.REACT_APP_API_LINK + "/api/files/status/" + this.state.instance.Id + "/", {
                            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                        })
                        .then(res => {
                            this.changeFilesStatus(res.data);
                            this.setState({
                                loading: false,
                                files: res.data
                            });
                            console.log(res);
                        })
                })
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    }

    deleteFile = fileId => {
        if (localStorage.getItem('token')) {
            axios.delete(process.env.REACT_APP_API_LINK + '/api/files/' + fileId + "/", {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            })
            .then(res => {
                axios.get(process.env.REACT_APP_API_LINK + "/api/files/status/" + this.state.instance.Id + "/", {
                        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                    })
                    .then(res => {
                        this.setState({
                            loading: false,
                            files: res.data
                        });
                        console.log(res);
                    })
            })
        }
    }

    render() {
        return (
            <>  
                {this.state.dynamicData.step.EditPermission.includes(this.state.logged_user.PersonRole) && this.state.instance.IsAssigned && (this.state.instance.UserAssignedId == this.state.logged_user.Id) ?                                                                            
                    <>  
                        <Row>
                            <Col lg="10"> 
                                <label className="btn btn-primary" htmlFor={this.state.dynamicData.step.Id + "/" + this.state.dynamicActions.action.Id} >
                                    Επιλογή αρχείου
                                </label>
                                <i className="fa fa-info-circle" data-tip data-for={'info-form-control-alternative' + this.state.dynamicData.step.Id + this.state.dynamicActions.action.Id} aria-hidden="true"></i>
                                {/* <ReactTooltip id={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
                                    <span>Μέγιστο μέγεθος αρχείου 3,5ΜΒ.</span>
                                    <div id="imageattooltip"></div>
                                </ReactTooltip> */}
                                <Input
                                    className="form-control-alternative"
                                    // defaultValue={ dynamicActions.data.Data }
                                    id={this.state.dynamicData.step.Id + "/" + this.state.dynamicActions.action.Id}
                                    // placeholder={dynamicActions.action.Description}
                                    style={{visibility:"hidden"}}
                                    type="file"
                                    disabled={!this.state.enabled}
                                    onChange={evt => this.uploadFile(evt, this.state.dynamicData.step.Id + "/" + this.state.dynamicActions.action.Id)}
                                />
                            </Col>
                        </Row>
                    </> 
                    : <> </>
                }

                <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Ονομα</th>
                            <th scope="col">Ημερομηνια Υποβολης</th>
                            <th scope="col">Ενεργειες</th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        (this.state.files).map((dynamicFiles) => 
                            <>
                            { this.state.dynamicActions.action.Id == dynamicFiles.ActionId 
                                ?
                                    <> 
                                        <tr>
                                            <td>{dynamicFiles.FileName}</td>
                                            <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY')}</td>
                                            <td>
                                                <Button
                                                    color="primary"
                                                    href={dynamicFiles.Link}
                                                    size="sm"
                                                    title="Προβολή"
                                                    target="_blank"
                                                >
                                                    <i className="fas fa-eye" />
                                                </Button>
                                                <Button
                                                    color="danger"
                                                    onClick={() => { this.deleteFile(dynamicFiles.Id) }}
                                                    size="sm"
                                                    title="Διαγραφή"
                                                >
                                                    <i className="fas fa-trash" />
                                                </Button> 
                                            </td>
                                        </tr> 
                                    </>
                                : <></>
                            }
                            </>
                        ) 

                    }
                    </tbody>
                </Table>
            </>
        );
    }
}

export default File;
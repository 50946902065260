import React from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import axios from 'axios';
import { MDBDataTable } from 'mdbreact';
import { v4 as uuidv4 } from 'uuid';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    NavItem,
    NavLink,
    Nav,
    Progress,
    Table,
    Container,
    Row,
    Col,
    Form,
    Input,
    FormGroup
} from "reactstrap";

import InstanceHeader from "components/Headers/InstanceHeader.js";

import ReactTooltip from 'react-tooltip';

import GenericHeader from "components/Headers/GenericHeader.js";
import Moment, { defineLocale } from "moment";
import 'moment/locale/el';

// import { Cascader } from 'antd';

class newInstance extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            workflows: [],
            selectedWorkflow: '',
            instanceId: '',
            submitButton: 0,
            steps: [],
            files: [],
            dropdownActive: 1,
            createButton: 0
        };

        this.changeType = this.changeType.bind(this);
    };

    handleClick = wId => {
        this.setState({ 
            submitButton:  0,
            dropdownActive:  0,
            createButton: 1
        });

        axios.get(process.env.REACT_APP_API_LINK + "/api/instances/initiliaze/" + this.state.selectedWorkflow + "/", {
            headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
        })
        .then(res => {
            console.log(res.data);
            this.setState({
                steps: res.data.stepsInfused,
                fileUpload: null,
                selectedWorkflow: this.state.selectedWorkflow
            });
        })
    }

    createInstance = instanceId => {
        this.setState({ 
            createButton:  0
        });

        fetch(process.env.REACT_APP_API_LINK + "/api/instances/new/", {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
            }),
            body: JSON.stringify({
                'InstanceId': this.state.instanceId,
                'WorkflowId': this.state.selectedWorkflow
            })
        })
        .then(res => res.json())
        .then(data => {
            // console.log(data);
            fetch(process.env.REACT_APP_API_LINK + '/api/instances/submit/' + this.state.instanceId + '/', {
                method: 'POST',
                headers: new Headers({
                    'Authorization': 'Bearer ' + localStorage.getItem('token'),
                }),
                body: JSON.stringify(this.state.steps[0])
            })
            .then(data => {
                window.location.replace("/basicLayout/instance/" + this.state.instanceId);
            })
        })
    }

    async componentDidMount() {
        if (localStorage.getItem('token')) {
            await fetch(process.env.REACT_APP_API_LINK + "/api/flows/all/", {
              method: 'get',
              headers: new Headers({
                'Authorization' : 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
              })
            })
            .then(res => res.json())
            .then(
              (result) => {
                this.setState({
                  workflows: result,
                  instanceId: uuidv4()
                });
                console.log(result);
              },
              (error) => {
                this.setState({
                  error
                });
              }
            );
        };
    }

    changeType(event) {
        this.setState({ selectedWorkflow: event.target.value });

        if (event.target.value != '0') {
            this.setState({ submitButton: 1 });
        }

        if (event.target.value == '0') {
            this.state.submitButton = 0;
        }
    }

    updateFile(evt, test) {
        console.log(test);
        this.setState({ fileUpload: evt.target.files[0] });
    }

    deleteFile = (fileId) => {
        var self = this;
        if (localStorage.getItem('token')) {
            axios.delete(process.env.REACT_APP_API_LINK + '/api/files/' + fileId + "/", {
                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
            })
                .then(res => {
                    axios.get(process.env.REACT_APP_API_LINK + "/api/files/instance/" + this.state.instanceId + "/", {
                        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                    })
                    .then(res => {
                        this.setState({
                            loading: false,
                            files: res.data
                        });
                    })
                })
        }
    }

    uploadFile(evt, fileid) {
        const formData = new FormData();
        var res = fileid.split("/");

        // Update the formData object 
        formData.append(
            "files",
            evt.target.files[0]
        );

        // Details of the uploaded file 
        console.log(evt.target.files[0]);
        console.log(formData);


        fetch(
                'https://rae-filemanager-staging.evolution-isa.gr/', {
                    method: 'POST',
                    body: formData,
                }
            )
            .then((response) => response.json())
            .then((result) => {
                console.log('Success:', result);

                fetch(process.env.REACT_APP_API_LINK + '/api/files/new/', {
                        method: 'POST',
                        headers: new Headers({
                            'Authorization': 'Bearer ' + localStorage.getItem('token'),
                        }),
                        body: JSON.stringify({
                            'Name': result[0].name,
                            'Path': result[0].path,
                            'Size': result[0].size,
                            'Type': result[0].type,
                            'InstanceId': this.state.instanceId,
                            'StepId': res[0],
                            'ActionId': res[1]
                        })
                    })
                    .then(data => {
                        console.log(data);

                        axios.get(process.env.REACT_APP_API_LINK + "/api/files/instance/" + this.state.instanceId + "/", {
                                headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
                            })
                            .then(res => {
                                this.setState({
                                    loading: false,
                                    files: res.data
                                });
                                console.log(res);
                            })
                    })
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    }

    updateInputValue(evt) {
        // console.log(evt);
        var res = evt.target.id.split("/");
        let data = [...this.state.steps];


        var i;
        for (i = 0; i < (this.state.steps).length; i++) {
            if (this.state.steps[i].step.Id == res[0]) {
                var j;
                for (j = 0; j < (this.state.steps[i].actions).length; j++) {
                    if (this.state.steps[i].actions[j].action.Id == res[1]) {
                        let tempAction = {...data[i].actions[j] };
                        
                        console.log(tempAction);
                        if (tempAction.action.Target == 'limit14') {
                            if ((evt.target.value).length > 14) {
                                evt.target.value = evt.target.value.substring(0, evt.target.value.length - 1);
                            }
                        }
                
                        if (tempAction.action.Target == 'limit9') {
                            if ((evt.target.value).length > 9) {
                                evt.target.value = evt.target.value.substring(0, evt.target.value.length - 1);
                            }
                        }
                        tempAction.data.Data = evt.target.value;
                        data[i].actions[j] = tempAction;

                        this.setState({
                            steps: data
                        });
                    }
                }
            }
        }
    }

    render() {
        return (
            <>
                <InstanceHeader />
                <div className="header pb-4" style={{ background: "linear-gradient(to right, #a8e6ff, #095d80)" }}>
                    <Container fluid>
                        <div className="header-body">
                            <Row>
                                <Col lg="8" xl="12">
                                    <Card className="card-stats mb-4 mb-xl-0">
                                        <CardBody>
                                            <Row>
                                                <div className="col">
                                                    <CardTitle
                                                        tag="h5"
                                                        className="text-uppercase text-muted mb-0"
                                                    >
                                                    </CardTitle>
                                                    <span className="h4 font-weight-bold mb-0">
                                                        Υποβολή Νέας Εργασίας
                                                    </span>
                                                </div>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <Container className="mt-4" fluid>
                    {this.state.dropdownActive == 1 ? 
                        <Row className="mt-5">
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            Υποβολή Νέου Αιτήματος
                                        </Col>
                                        <Col className="text-right" xs="4">
                                            {this.state.submitButton == 1?
                                                <Button
                                                    color="success"
                                                    onClick={() => { this.handleClick() }}
                                                    size="sm"
                                                >
                                                    Συνέχεια <i className="fas fa-chevron-right" />
                                                </Button>
                                                :
                                                <></>
                                            }
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <div className="pl-lg-4">
                                            <Row>
                                                <Col lg="6">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="consumer-client"
                                                        >
                                                            Ροή Εργασίας
                                                        </label>
                                                        &nbsp;<i className="fa fa-info-circle" data-tip data-for={'info-katigoria-aitimatos'} aria-hidden="true"></i>
                                                        <Input
                                                            className="form-control-alternative"
                                                            id="consumer-client"
                                                            type="select"
                                                            onChange={this.changeType}
                                                        >
                                                            <option value='0'>-- Επιλέξτε ροή εργασίας --</option>
                                                            { this.state.workflows.map((dynamicWorkflows) =>
                                                                <option value={dynamicWorkflows.Id}>{dynamicWorkflows.Name}</option>
                                                            )}
                                                        </Input>
                                                        <ReactTooltip id={'info-katigoria-aitimatos'} type='info'>
                                                            <span>Επιλέξτε ροή εργασίας</span>
                                                        </ReactTooltip>
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    :
                    <>
                    {this.state.steps.map((dynamicData) =>
                        <>
                            {dynamicData.step.Status == 1 ?
                                <>
                                    {dynamicData.step.Conditional ?
                                        <Container className="mt-2" fluid>
                                            <Row className="mt-5">
                                                <Col className="order-xl-1" xl="12">
                                                    <Card className="bg-secondary shadow">
                                                        <CardHeader className="bg-white border-0">
                                                            <Row className="align-items-center">
                                                                <Col xs="8">
                                                                    <h3 className="mb-0">{dynamicData.step.Name}</h3>
                                                                </Col>
                                                                <Col className="text-right" xs="4">
                                                                    <Button
                                                                        color="success"
                                                                        onClick={() => { this.createInstance() }}
                                                                        size="sm"
                                                                        id="submit-button-1"
                                                                    >
                                                                        Δημιουργία
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <Form>
                                                                <div className="pl-lg-4">
                                                                    <Row>
                                                                        {dynamicData.actions.map((dynamicActions) =>
                                                                            <>
                                                                                    <Col lg="12">
                                                                                        <FormGroup>
                                                                                            <label
                                                                                                className="form-control-label"
                                                                                                htmlFor="input-username"
                                                                                            >
                                                                                                {dynamicActions.action.Name}
                                                                                            </label>
                                                                                            {/* </>} */}
                                                                                            {dynamicActions.action.Type == 'file' ?
                                                                                                <>
                                                                                                    <Row>
                                                                                                        <Col lg="10">
                                                                                                            <label className="btn btn-primary" htmlFor={dynamicData.step.Id + "/" + dynamicActions.action.Id}>
                                                                                                                Επιλογή αρχείου
                                                                                                            </label>
                                                                                                            &nbsp;
                                                                                                            <i className="fa fa-info-circle" data-tip data-for={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>
                                                                                                            <ReactTooltip id={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
                                                                                                                <span>Μέγιστο μέγεθος αρχείου 3,5ΜΒ.</span>
                                                                                                                <div id="imageattooltip"></div>
                                                                                                            </ReactTooltip>
                                                                                                            <Input
                                                                                                                className="form-control-alternative"
                                                                                                                // defaultValue={ dynamicActions.data.Data }
                                                                                                                id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                // placeholder={dynamicActions.action.Description}
                                                                                                                style={{ visibility: "hidden" }}
                                                                                                                type={dynamicActions.action.Type}
                                                                                                                disabled={!dynamicActions.action.Required}
                                                                                                                onChange={evt => this.uploadFile(evt, dynamicData.step.Id + "/" + dynamicActions.action.Id)}
                                                                                                            />
                                                                                                        </Col>
                                                                                                    </Row>

                                                                                                    <Table className="align-items-center table-flush" responsive>
                                                                                                        <thead className="thead-light">
                                                                                                            <tr>
                                                                                                                <th scope="col">Ονομα</th>
                                                                                                                <th scope="col">Ημερομηνια Υποβολης</th>
                                                                                                                <th scope="col">Ενεργειες</th>
                                                                                                            </tr>
                                                                                                        </thead>
                                                                                                        <tbody>
                                                                                                            {
                                                                                                                this.state.files.map((dynamicFiles) =>
                                                                                                                    <>
                                                                                                                        {dynamicActions.action.Id == dynamicFiles.ActionId ?
                                                                                                                            <>
                                                                                                                                <tr>
                                                                                                                                    <td>{dynamicFiles.FileName}</td>
                                                                                                                                    <td>{Moment(dynamicFiles.UploadDate).locale('el').format('dddd, DD MMM YYYY h:mm:ss a')}</td>
                                                                                                                                    <td>
                                                                                                                                        <Button
                                                                                                                                            color="primary"
                                                                                                                                            href={dynamicFiles.Link}
                                                                                                                                            target="_blank"
                                                                                                                                            size="sm"
                                                                                                                                            title="Προβολή"
                                                                                                                                        >
                                                                                                                                            <i className="fas fa-eye" />
                                                                                                                                        </Button>
                                                                                                                                        {console.log(dynamicFiles)}
                                                                                                                                        <Button
                                                                                                                                            color="danger"
                                                                                                                                            onClick={() => { this.deleteFile(dynamicFiles.Id) }}
                                                                                                                                            size="sm"
                                                                                                                                            title="Διαγραφή"
                                                                                                                                        >
                                                                                                                                            <i className="fas fa-trash" />
                                                                                                                                        </Button>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </> : <></>
                                                                                                                        }
                                                                                                                    </>
                                                                                                                )}
                                                                                                        </tbody>
                                                                                                    </Table>
                                                                                                    {/* </> } */}
                                                                                                </>
                                                                                                : <>{dynamicActions.action.Type == 'button' ?
                                                                                                    <>
                                                                                                        <Col lg="6">
                                                                                                            <Button
                                                                                                                color="primary"
                                                                                                                onClick={evt => this.dynamicCall(dynamicActions.action.Target)}
                                                                                                                size="sm"
                                                                                                            >
                                                                                                                {dynamicActions.action.Description}
                                                                                                            </Button>
                                                                                                        </Col>
                                                                                                    </>
                                                                                                    : <>{dynamicActions.action.Type == 'textarea' ?
                                                                                                        <React.Fragment>
                                                                                                            &nbsp;<i className="fa fa-info-circle" data-tip data-for={'info-textarea' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>
                                                                                                            <ReactTooltip id={'info-textarea' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
                                                                                                                <span>{dynamicActions.action.Description}</span>
                                                                                                            </ReactTooltip>
                                                                                                            <Input
                                                                                                                className="form-control-alternative"
                                                                                                                defaultValue={dynamicActions.data.Data}
                                                                                                                id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                placeholder={dynamicActions.action.Description}
                                                                                                                type='textarea'
                                                                                                                disabled={!dynamicActions.action.Required}
                                                                                                                onChange={evt => this.updateInputValue(evt)}
                                                                                                            />
                                                                                                        </React.Fragment>

                                                                                                        : <> {dynamicActions.action.Type == 'number' ?
                                                                                                            <React.Fragment>
                                                                                                                <i className="fa fa-info-circle" data-tip data-for={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>
                                                                                                                <Input
                                                                                                                    className="form-control-alternative"
                                                                                                                    defaultValue={dynamicActions.data.Data}
                                                                                                                    id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                    placeholder={dynamicActions.action.Description}
                                                                                                                    type={dynamicActions.action.Type}
                                                                                                                    disabled={!dynamicActions.action.Required}
                                                                                                                    onChange={evt => this.updateInputValueNumber(evt, dynamicActions)}

                                                                                                                />

                                                                                                                <ReactTooltip id={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
                                                                                                                    <span>{dynamicActions.action.Description}</span>
                                                                                                                </ReactTooltip>
                                                                                                            </React.Fragment>

                                                                                                            : <> {dynamicActions.action.Type == 'dropdown' ?

                                                                                                                <React.Fragment>
                                                                                                                    <i className="fa fa-info-circle" data-tip data-for={'info-dropdown' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>
                                                                                                                    <Row>
                                                                                                                        <Col lg="10">
                                                                                                                            <ReactTooltip id={'info-dropdown' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
                                                                                                                                <span>{dynamicActions.action.Description}</span>
                                                                                                                            </ReactTooltip>
                                                                                                                            {/* <Cascader id={(dynamicData.step.Id + "/" + dynamicActions.action.Id)} className={"dropdown"} options={JSON.parse(dynamicActions.action.Target)} onChange={this.updateInputValueCascader} placeholder={"Î•Ï€Î¹Î»Î­Î¾Ï„Îµ ÎºÎ±Ï„Î·Î³Î¿ÏÎ¯Î±"} style={{ width: '100%' }} /> */}
                                                                                                                        </Col>
                                                                                                                    </Row>
                                                                                                                </React.Fragment>
                                                                                                                :
                                                                                                                <React.Fragment>
                                                                                                                    <i className="fa fa-info-circle" data-tip data-for={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} aria-hidden="true"></i>
                                                                                                                    <Input
                                                                                                                        className="form-control-alternative"
                                                                                                                        defaultValue={dynamicActions.data.Data}
                                                                                                                        id={dynamicData.step.Id + "/" + dynamicActions.action.Id}
                                                                                                                        placeholder={dynamicActions.action.Description}
                                                                                                                        type={dynamicActions.action.Type}
                                                                                                                        disabled={!dynamicActions.action.Required}
                                                                                                                        onChange={evt => this.updateInputValue(evt)}

                                                                                                                    />

                                                                                                                    <ReactTooltip id={'info-form-control-alternative' + dynamicData.step.Id + dynamicActions.action.Id} type='info'>
                                                                                                                        <span>{dynamicActions.action.Description}</span>
                                                                                                                    </ReactTooltip>

                                                                                                                </React.Fragment>
                                                                                                            }
                                                                                                            </>
                                                                                                        }
                                                                                                        </>
                                                                                                    }
                                                                                                    </>
                                                                                                }</>
                                                                                            }
                                                                                        </FormGroup>
                                                                                    </Col>
                                                                            </>
                                                                        )}
                                                                    </Row>
                                                                </div>
                                                            </Form>
                                                        </CardBody>
                                                        <CardFooter className="bg-white border-0">
                                                            <Row className="align-items-center">
                                                                <Col xs="8">
                                                                    {/* <h3 className="mb-0">{dynamicData.step.Name}</h3> */}
                                                                </Col>
                                                                <Col className="text-right" xs="4">
                                                                    <Button
                                                                        color="success"
                                                                        onClick={() => { this.createInstance() }}
                                                                        size="sm"
                                                                        id="submit-button-1"
                                                                    >
                                                                        Δημιουργία
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                        </CardFooter>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Container>
                                        : <Container className="mt-2" fluid>
                                            <Row className="mt-5">
                                                <Col className="order-xl-1" xl="12">
                                                    <Card className="bg-secondary shadow">
                                                        <CardBody>
                                                            <Form>
                                                                <div className="pl-lg-4">
                                                                    <Row>
                                                                        <Col lg="12">
                                                                            <p style={{ fontSize: '18px' }}>Î˜Î± ÎµÎ½Î·Î¼ÎµÏÏ‰Î¸ÎµÎ¯Ï„Îµ Î±Ï€ÏŒ Ï„Î¿Î½ ÎˆÎ»ÎµÎ³Ï‡Î¿ Î Î¿ÏÎµÎ¯Î±Ï‚ Î³Î¹Î± Ï„Î¿ ÎµÏ€ÏŒÎ¼ÎµÎ½Î¿ ÏƒÏ„Î¬Î´Î¹Î¿ Ï„Î·Ï‚ Î±Î¯Ï„Î·ÏƒÎ·Ï‚ Ï€Î¿Ï… Î¸Î± Ï‡ÏÎµÎ¹Î±ÏƒÏ„ÎµÎ¯ Î½Î± ÏƒÏ…Î¼Ï€Î»Î·ÏÏŽÏƒÎµÏ„Îµ.</p>
                                                                            <Button
                                                                                color="primary"
                                                                                href={"/consumer/instance/" + this.state.instance.Id}
                                                                                size="lg"
                                                                            >
                                                                                ÎˆÎ»ÎµÎ³Ï‡Î¿Ï‚ Ï€Î¿ÏÎµÎ¯Î±Ï‚
                                                                            </Button>
                                                                        </Col>
                                                                    </Row>
                                                                </div>
                                                            </Form>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Container>
                                    }
                                </>
                                :
                                <> </>
                            }
                        </>
                    )}
                    </>
                    }
                </Container>
            </>
        );
    }

}

export default newInstance;
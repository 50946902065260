import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Table
} from "reactstrap";
// core components

import Switch from "react-switch";
import { CommonLoading } from 'react-loadingg';
import Moment from "moment";
import 'moment/locale/el';
import axios from 'axios';

import GenericHeader from "components/Headers/GenericHeader.js";
import {ApiCallerHelper} from "../../../../api/apiCallerHelper";
import DynamicGridView from "../../../../components/DynamicPages/DynamicGridView/dynamicGridView.js";
import { RedirectHelper } from "../../../../common/redirectHelper";

class Clients extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          error: null,
          loading: false,
          checked: false,
          instances: [],
          details: [],
          user: []
        };
      }
    
      async componentDidMount() {
        await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
          method: 'get',
          headers: new Headers({
              'Authorization' : 'Bearer ' + localStorage.getItem('token'),
              'Content-Type': 'application/x-www-form-urlencoded'
          })
        })
        .then(res => res.json())
        .then(
          (result) => {
              this.setState({
              isLoaded: true,
              user: result,
              });
          },
          (error) => {
              this.setState({
              isLoaded: true,
              error
              });
          }
        )
      }

    
  render() {
    if (this.state.loading) {
      return (
          <>
              <GenericHeader />
              <CommonLoading />
          </>
      ) 
    }
    else {
      return (
        <>
          <GenericHeader />
          <Container className="mt--7" fluid>
            <Row>
                <Col className="order-xl-1" xl="12">
                    <Card className="bg-secondary shadow">
                        <CardHeader className="bg-white border-0">
                            <Row className="align-items-center">
                                <Col xs="8">
                                    <h3 className="mb-0">Στοιχεία Πελατών</h3>
                                </Col>
                                <Col className="text-right" xs="4" />
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <DynamicGridView
                                gridViewId="e65f4695-4e65-4243-9bc7-7ccb066f2272"                        
                            />
                        </CardBody>
                    </Card>
                </Col>
              </Row>
          </Container>
        </>
      );
    }
  }
}

export default Clients;

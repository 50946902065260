import {
    get, getAsync,
    post, postAsync,
    put, putAsync,
    del, deleteAsync,
    getAuthorizationHeader,
    hasInitialisedToken,
    ContentTypeEnum
} from "./apiCallerHelper.core";

// export async function getAllMeters() {
//     var route = "/api/meters/all/";
//     return getAsync(route);
// }

export async function setMOPBilling(meterId, checked) {
    var route = "/api/meters/mopbilling/";
    let data = {
      MeterId : meterId,
      Checked: checked
    }

    return putAsync(route, data);
}

export async function setMOPDeclaration(meterId, declaration, year) {
    var route = "/api/meters/mopdeclaration/";
    let data = {
      MeterId : meterId,
      Declaration: declaration,
      Year: year
    }

    return putAsync(route, data);
}

import React, { useState } from "react";
// node.js library that concatenates classes (strings)
import classnames from "classnames";

import { CommonLoading } from 'react-loadingg';


import axios from 'axios';

import {
  Button,
  Collapse,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Tooltip 
} from "reactstrap";

import GenericHeader from "components/Headers/GenericHeader.js";
import Accordion from 'react-bootstrap/Accordion';
import Moment from "moment";
import 'moment/locale/el';
import Modal from 'react-bootstrap/Modal';

import "../../../../assets/css/modals.css";

class SystemSettings extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error: null,
            user: [],
            show: false
        };
    }

    async componentDidMount() {
        await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
            method: 'get',
            headers: new Headers({
                'Authorization' : 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/x-www-form-urlencoded'
            })
          })
          .then(res => res.json())
          .then(
            (result) => {
              console.log(result);
              this.setState({
              user: result,
              });
            },
            (error) => {
              console.log(error);
                this.setState({
                error
                });
            }
          )
    };

    setShow(boolean) {
        this.setState({
            show: boolean
        });
    }

    saveSidebar() {
        this.setShow(false);
    }

    render() {
        return (
            <>
                <GenericHeader />
                <Container className="mt--7" fluid>
                    <Row className="mt-3">
                        <Col className="mb-5 mb-xl-0" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Ρυθμίσεις Συστήματος</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>

                    {/* <Row className="mt-4">
                        <Col className="mb-5 mb-xl-0" xl="6">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Επιλογές Σύνδεσης</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                   
                                    <Form>
                                        <div className="pl-lg-4">
                                            <Col md="12" >
                                                <h6 className="heading-small text-muted mb-4">
                                                    Συνδεση με Facebook
                                                </h6>
                                                <label className="custom-toggle">
                                                    <Input type="checkbox" />
                                                    <span className="custom-toggle-slider rounded-circle" ></span>
                                                </label>
                                            </Col>
                                            <Col md="12" >
                                                <h6 className="heading-small text-muted mb-4">
                                                    Συνδεση με Google
                                                </h6>
                                                <label className="custom-toggle">
                                                    <Input type="checkbox" />
                                                    <span className="custom-toggle-slider rounded-circle" ></span>
                                                </label>
                                            </Col>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col className="mb-5 mb-xl-0" xl="6">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Επιλογές Επαλήθευσης Εγγραφής</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                        <div className="pl-lg-4">
                                            <Col md="12" >
                                                <h6 className="heading-small text-muted mb-4">
                                                    Επαληθευση με Email
                                                </h6>
                                                <label className="custom-toggle">
                                                    <Input type="checkbox" checked='true'/>
                                                    <span className="custom-toggle-slider rounded-circle"></span>
                                                </label>
                                            </Col>
                                            <Col md="12" >
                                                <h6 className="heading-small text-muted mb-4">
                                                    Επαληθευση με SMS
                                                </h6>
                                                <label className="custom-toggle">
                                                    <Input type="checkbox"/>
                                                    <span className="custom-toggle-slider rounded-circle"></span>
                                                </label>
                                            </Col>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> */}
                    
                    <Row className="mt-4">
                        <Col className="order-xl-1" xl="12">
                            <Card className="bg-secondary shadow">
                                <CardHeader className="bg-white border-0">
                                    <Row className="align-items-center">
                                        <Col xs="8">
                                            <h3 className="mb-0">Λοιπά</h3>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <Form>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col md="12" >
                                                <h6 className="heading-small text-muted mb-4">
                                                    Επιλογες
                                                </h6>
                                                <Button
                                                    color="primary"
                                                    href={"/basicLayout/roles/"}
                                                    size="sm"
                                                    >
                                                    Ρόλοι Χρηστών Συστήματος
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    // href={"/basicLayout/users/"}
                                                    size="sm"
                                                >
                                                    Χρήστες Συστήματος
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    onClick={() => this.setShow(true)}
                                                    size="sm"
                                                    >
                                                    Τροποποίηση Πλαϊνού Μενού
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    // href={"/basicLayout/entities/"}
                                                    size="sm"
                                                >
                                                    Τροποποίηση οντοτήτων
                                                </Button>
                                                <Button
                                                    color="primary"
                                                    href={"/basicLayout/templates/"}
                                                    size="sm"
                                                >
                                                    Πρότυπα εγγράφων
                                                </Button>
                                                <Button
                                                    color="danger"
                                                    // onClick={() => { this.lockSystem() }}
                                                    size="sm"
                                                >
                                                    Κλείδωμα συστήματος
                                                </Button>
                                                {/* <hr className="my-4" /> */}
                                            </Col>                                        
                                        </Row>
                                    </div>

                                    
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    
                    <div className='push'></div>
                    <Modal
                        show={this.state.show}
                        size="sm"
                        onHide={() => this.setShow(false)}
                        dialogClassName="modal-90w"
                        aria-labelledby="example-custom-modal-styling-title-sm"
                        style={{ opacity: 1 }}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title id="example-custom-modal-styling-title-sm">
                                Τροποποίηση Πλαϊνού Μενού
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <div className="pl-lg-4">
                                    {/* {this.state.menu.map((dynamicMenu => 
                                        <Row>
                                            <Col lg="2" />
                                            <Col lg="8" style={{ textAlign: 'center' }}>
                                                <Row>
                                                    <Col lg="6">
                                                        <h6 className="heading-small text-muted mb-4">
                                                            <i className={dynamicMenu.Icon}/> {dynamicMenu.TableName}
                                                        </h6>
                                                    </Col>
                                                    <Col lg="6">
                                                        <label className="custom-toggle">
                                                            <Input type="checkbox"/>
                                                            <span className="custom-toggle-slider rounded-circle"></span>
                                                        </label>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col lg="2" />
                                        </Row>
                                    ))} */}

                                    <Row>
                                        <Col lg="2" />
                                        <Col lg="8" style={{ textAlign: 'center' }}>
                                            <Row>
                                                <Col lg="6">
                                                    <h6 className="heading-small text-muted mb-4">
                                                       <i className="fa fa-stream text-primary mr-3" /> Επιλογη 1
                                                    </h6>
                                                </Col>
                                                <Col lg="6">
                                                    <label className="custom-toggle">
                                                        <Input type="checkbox"/>
                                                        <span className="custom-toggle-slider rounded-circle"></span>
                                                    </label>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col lg="2" />
                                    </Row>
                                </div>

                            </Form>
                            <div className="col text-center">
                                <Button
                                    color="success"
                                    onClick={() => this.saveSidebar()}
                                    size="sm"
                                >
                                    Αποθήκευση Αλλαγών
                                </Button>

                            </div>
                        </Modal.Body>
                    </Modal>
            </Container>
        </>
        );
    }
}

export default SystemSettings;

import React from 'react';

import {
    Button,
    Card,
    CardBody,
    Container,
    Row,
    Col,
    Input
  } from "reactstrap";

import DatePicker from "react-datepicker";

class StatsFilter extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            error: null,
            startDate: new Date(),
            otherDay: new Date(),
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleChange2 = this.handleChange2.bind(this);
        this.state.startDate.setMonth(this.state.otherDay.getMonth() - 2);
    }

    async componentDidMount() {
        // await fetch(process.env.REACT_APP_API_LINK + "/api/auth/user/", {
        //     method: 'get',
        //     headers: new Headers({
        //         'Authorization' : 'Bearer ' + localStorage.getItem('token'),
        //         'Content-Type': 'application/x-www-form-urlencoded'
        //     })
        //   })
        //   .then(res => res.json())
        //   .then(
        //     (result) => {
        //       console.log(result);
        //       this.setState({
        //       user: result,
        //       });
        //     },
        //     (error) => {
        //       console.log(error);
        //         this.setState({
        //         error
        //         });
        //     }
        //   )
    };

    getDates(startDate, endDate) {
        var dates = [],
            currentDate = startDate,
            addDays = function (days) {
                var date = new Date(this.valueOf());
                date.setDate(date.getDate() + days);
                return date;
            };
        while (currentDate <= endDate) {
    
            dates.push(currentDate.toLocaleDateString('en-GB'));
            currentDate = addDays.call(currentDate, 1);
        }
        this.state.array = dates;
        return dates;
    };
    
    getDates_call(date, otherDate) {
        var dates = this.getDates(date, otherDate);
    };

    handleChange(date) {
        this.setState({
            startDate: date
        });
    }

    handleChange2(date) {
        this.setState({
            otherDay: date
        })
    }

    render() {
        return (
            <Container fluid>
                <div className="header-body">
                    <Row>
                        <Col lg="8" xl="12">
                            <Card className="card-stats mb-4 mb-xl-0">
                                <CardBody>
                                    <Row>
                                        <div className="col">
                                            <span className="h2 font-weight-bold mb-0">
                                                Στατιστικά
                                            </span>
                                        </div>
                                    </Row>

                                    <Row>
                                        <p></p>
                                    </Row>

                                    <Row className="align-items-center">
                                        <div className="container-fluid">
                                            <div className="row">
                                                <div className="col-sm">
                                                    Από
                                                    <DatePicker
                                                        selected={this.state.startDate}
                                                        onChange={this.handleChange}
                                                        name="startDate"
                                                        dateFormat="dd/MM/yyyy"
                                                    />
                                                </div>
                                                {this.getDates_call()}
                                                <div className="col-sm">
                                                    Έως
                                                    <DatePicker
                                                        selected={this.state.otherDay}
                                                        onChange={this.handleChange2}
                                                        name="startDate"
                                                        dateFormat="dd/MM/yyyy"
                                                    />
                                                </div>
                                                <div className="col-sm" style={{ marginTop: '-10px' }}>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-first-name"
                                                    >
                                                        Κατηγορία
                                                    </label>
                                                    <Input
                                                        style={{ height: '40px', border: '1px solid rgb(118, 118, 118)' }}
                                                        className="form-control-alternative"
                                                        defaultValue={''}
                                                        id="input-first-name"
                                                        type="select"
                                                        // onChange={evt => this.updateLocation(evt)}
                                                    >
                                                        <option value={'-'}>{'Όλες οι κατηγορίες'}</option>
                                                        {/* {this.state.locations.map((dynamicLocations) =>
                                                            <option value={dynamicLocations.Id}>{dynamicLocations.City}</option>
                                                        )} */}
                                                    </Input>
                                                </div>
                                                <div className="col-sm" style={{ marginTop: '-10px' }}>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-first-name"
                                                    >
                                                        Είδος Γραφήματος
                                                    </label>
                                                    <Input
                                                        style={{ height: '40px', border: '1px solid rgb(118, 118, 118)' }}
                                                        className="form-control-alternative"
                                                        defaultValue={''}
                                                        id="input-first-name"
                                                        type="select"
                                                        // onChange={evt => this.updateLocation(evt)}
                                                    >
                                                        <option value={'-'}>{'Όλες οι κατηγορίες'}</option>
                                                        <option value={'line'}>{'Γραμμή'}</option>
                                                        <option value={'pie'}>{'Πίτα'}</option>
                                                        <option value={'bar'}>{'Μπάρες'}</option>
                                                        {/* {this.state.locations.map((dynamicLocations) =>
                                                            <option value={dynamicLocations.Id}>{dynamicLocations.City}</option>
                                                        )} */}
                                                    </Input>
                                                </div>

                                                <div className="col-sm" style={{ marginTop: '-10px' }}>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-first-name"
                                                    >
                                                        Είδος Δεδομένων
                                                    </label>
                                                    <Input
                                                        style={{ height: '40px', border: '1px solid rgb(118, 118, 118)' }}
                                                        className="form-control-alternative"
                                                        defaultValue={''}
                                                        id="input-first-name"
                                                        type="select"
                                                        // onChange={evt => this.updateLocation(evt)}
                                                    >
                                                        <option value={'-'}>{'Όλες οι κατηγορίες'}</option>
                                                        <option value={'numbers'}>{'Αριθμοί'}</option>
                                                        <option value={'percentage'}>{'Ποσοστό'}</option>
                                                        <option value={'both'}>{'Όλα τα παραπάνω'}</option>
                                                        {/* {this.state.locations.map((dynamicLocations) =>
                                                            <option value={dynamicLocations.Id}>{dynamicLocations.City}</option>
                                                        )} */}
                                                    </Input>
                                                </div>


                                            </div>
                                        </div>
                                    </Row>

                                    <Row>
                                        <p></p>
                                    </Row>

                                    <Row>
                                    <div className="col text-right">
                                        <Button
                                            color="primary"
                                            // onClick={}
                                            size="sm"
                                        >
                                            Προβολή
                                        </Button>
                                    </div>
                                        </Row>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Container>
        );
    }
}

export default StatsFilter;
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

class GenericHeader extends React.Component {
  render() {
    return (
      <>
        <div className="header pb-8 pt-5 pt-md-8" style={{background: "linear-gradient(to right, #a8e6ff, #095d80)"}}>
          <Container fluid>
            <div className="header-body" />
          </Container>
        </div>
      </>
    );
  }
}

export default GenericHeader;

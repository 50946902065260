
import DynamicGridViewComponentCellExtentions from "./DynamicGridViewComponent.CellExtentions";
import EVOMDBDataTableHelper from "../../../components/common/evo.mdbDatatable-helper";

export const DynamicGridViewComponentColumnDefinitions = {
    GridType : {
        MDBDataTable: "MDBDataTable",
        EvoActionsDynamicGridComponent: "EvoActionsDynamicGridComponent"
    },
    getGridDatasource : (_datasource, _columnMetadataDefinitions, _gridType, _customActionsCallbackMethods) => {
        let datasource = _datasource || [];
        let columnMetadataDefinitions = _columnMetadataDefinitions || [];
        let gridType = _gridType || DynamicGridViewComponentColumnDefinitions.GridType.MDBDataTable;
    
        DynamicGridViewComponentCellExtentions.SetCellStyles(datasource, columnMetadataDefinitions, _customActionsCallbackMethods);        
        let gridDatasource = {
            columns: DynamicGridViewComponentColumnDefinitions._getColumnDefinitions(datasource, columnMetadataDefinitions),
            rows: datasource,
            columnMetadataDefinitions:columnMetadataDefinitions,
            gridType : gridType
        };                  
        return gridDatasource;
    },
    _getColumnDefinitions : (dataSource, columnMetadataDefinitions) => {        
        function getColumnsByColumnMetadataDefinitions(_columnMetadataDefinitions){
            let columns = []
            columnMetadataDefinitions.forEach(x => {
                if (x.isVisible !=null && x.isVisible == false){
                    return;
                }  
                let title =  x.title  + " ▼";
                let column = {
                    label: (<a onClick={()=>{EVOMDBDataTableHelper.customSortFunctional(dataSource, x.id)}}>{title}</a>),
                    //label: title,
                    field: "__column__" + x.id,   
                    //width: "1000px",//x.width??null
                    sort : "asc"            
                  };
                if (x.id === '__action'){                                        
                    column.label = x.title;  
                    column.field = "__action";  
                }     
                columns.push(column);       
            })
            return columns;
        }
        function getColumnsByDatasource(_dataSource){
            let columns = []
            if (_dataSource.length === 0) {
                return columns;
            }
            Object.keys(_dataSource[0]).forEach(key=> {   
                let label = String(key);                                 
                let column = {
                  label: label + " ▼",
                  field: key,
                  sort: "asc",
                };
                columns.push(column);
              });
            return columns;
        }

        let hasColumnMetadataDefinitions = (columnMetadataDefinitions !== null && columnMetadataDefinitions.length>0);
        if (hasColumnMetadataDefinitions) {
            return getColumnsByColumnMetadataDefinitions(columnMetadataDefinitions);            
        }
        //generate columns based on datasource result
        return getColumnsByDatasource(dataSource);
    }     
}
export default DynamicGridViewComponentColumnDefinitions
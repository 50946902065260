import {
  get, getAsync,
  post, postAsync,
  put, putAsync,
  del, deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
  ContentTypeEnum
  } from "./apiCallerHelper.core";
  
export async function getExportConnections() {
    var route = "/api/export/connections/";
    return getAsync(route, "blob");
  }

  export async function getExportTechnicalReview() {
    var route = "/api/export/technicalreview/";
    return getAsync(route, "blob");
  }

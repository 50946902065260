import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import BasicLayout from "layouts/basicLayout.js";

import Login from "views/examples/Login.js";
import SignUp from "views/examples/signup.js"

function setToken(userToken) {
    localStorage.setItem('token', userToken);
}

async function verifyToken(token) {
    await fetch(process.env.REACT_APP_API_LINK + "/api/users/current/", {
        method: 'get',
        headers: new Headers({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        })
    })
        //.then(res => res.json())
        .then(
            (result) => {
                // // console.log(result.status);
                if (result.status != 200) {
                    localStorage.removeItem('token');
                    window.location.replace("/auth/login");
                }
            },
            (error) => {
                // // console.log(error);
                localStorage.removeItem('token');
                window.location.replace("/auth/login");
            }
        )
};

function App() {
    const token = localStorage.getItem('token');

    if (!token) {
        if (window.location.href.includes('signup')) {
            <BrowserRouter>
                <Switch>
                    <Route path="/signup" render={props => <SignUp {...props} />} />
                </Switch>
            </BrowserRouter>
        }
        else {
            return <Login setToken={setToken} />
        }

    }
    else
    {
        verifyToken(token);
    }

    return (
        <BrowserRouter>
            <Switch>
                <Route path="/auth" render={props => <Login {...props} />} />
                <Route path="/basicLayout" render={props => <BasicLayout {...props} />} />
                <Route path="/signup" render={props => <SignUp {...props} />} />
                <Redirect from="/" to="/basicLayout/index" />
            </Switch>
        </BrowserRouter>
    );
}

export default App;

import {
  get, getAsync,
  post, postAsync,
  put, putAsync,
  del, deleteAsync,
  getAuthorizationHeader,
  hasInitialisedToken,
  ContentTypeEnum
  } from "./apiCallerHelper.core";
  
export async function getFilesStatusByInstanceId(instanceId) {
  var route = "/api/files/status/" + instanceId + "/";
  return getAsync(route);
}

export async function getAllInstanceFilesByInstanceId(instanceId) {
  var route = "/api/files/instance/" + instanceId + "/";
  return getAsync(route);
}

export async function getFilesForFunding(instanceId) {
  var route = "/api/files/funding/" + instanceId + "/";
  return getAsync(route);
}

export async function deleteFileByFileId(fileId) {
  var route = "/api/files/" + fileId + "/"
  return deleteAsync(route);
}


export async function getAllInstanceFiles(instanceId) {
  var route = "/api/files/instance/" + instanceId + "/"
  return getAsync(route);
}
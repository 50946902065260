import axios from "axios";
import { trackPromise } from 'react-promise-tracker';
import EVOAlertComponent from "../components/Abstract/EvoAlertComponent";

export const ContentTypeEnum = {
	ApplicationJson: 'application/json',
	ApplicationFormUrlEncoded: 'application/x-www-form-urlencoded',		
}

export async function get(route, successCallback) {
  if (!hasInitialisedToken()) {
    return;
  }
  const result = await getAsync(route);
  successCallback(result) 
}

export function getAsync(route, responseType) {
  responseType = responseType || null;
  if (!hasInitialisedToken()) {
    return;
  } 
  
  return trackPromise(
    axios
      .get(process.env.REACT_APP_API_LINK + route, {
        headers: getAuthorizationAndGenericHeader(),
        responseType : responseType,
      })
      .catch((error) => {
        EVOAlertComponent.DefaultError({});   
        console.error("ApiCallerHelper -> _get -> Catch ", error);
        throw error;
      })
  );
}

export async function post(route, body, successCallback) {
  if (!hasInitialisedToken()) {
    return;
  }

  const result = await postAsync(route);
  successCallback(result) 
}

export function postAsync(route, body) {
  if (!hasInitialisedToken()) {
    return;
  }
  return trackPromise(
    fetch(process.env.REACT_APP_API_LINK + route, {
    method: "POST",
    headers: new Headers(getAuthorizationAndGenericHeader()),
    body: JSON.stringify(body),
  })
  .then(res => res.json()) 
  .catch((error) => {
    EVOAlertComponent.DefaultError({});
    console.error("ApiCallerHelper -> postAsync -> Catch ", error);
    throw error;
  }));
}


export async function put(route, body, successCallback) {
  if (!hasInitialisedToken()) {
    return;
  }

  const result = await putAsync(route);
  successCallback(result) 
}

export function putAsync(route, body) {
  if (!hasInitialisedToken()) {
    return;
  }
  return fetch(process.env.REACT_APP_API_LINK + route, {
    method: "PUT",
    headers: new Headers(getAuthorizationAndGenericHeader()),
    body: JSON.stringify(body),
  })
  .then(res => res.json()) 
  .catch((error) => {
    EVOAlertComponent.DefaultError({});
    console.error("ApiCallerHelper -> putAsync -> Catch ", error);
    throw error;
  });
}

export async function del(route, successCallback) {
  if (!hasInitialisedToken()) {
    return;
  }
  const result = await deleteAsync(route);
  successCallback(result)  
}

export async function deleteAsync(route, contentType) {
  if (!hasInitialisedToken()) {
    return;
  }
  return trackPromise(
    axios
      .delete(process.env.REACT_APP_API_LINK + route, {
        headers: getAuthorizationAndGenericHeader(contentType),
      })   
      .catch((error) => {
        EVOAlertComponent.DefaultError({});
        console.error("ApiCallerHelper -> _delete -> Catch ", error);
      })
  );
}

export function getAuthorizationAndGenericHeader(contentType) {
  if (!hasInitialisedToken()) {
    throw Error("Error No Token found to execute api call");
  }
  let token = localStorage.getItem("token");
  contentType = contentType || 'application/json';
  return {
    Authorization: "Bearer " + token,    
    'Content-Type': contentType
  };
}

export function hasInitialisedToken() {
  if (localStorage.getItem("token")) {
    return true;
  }
  return false;
}
